import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import CustomCheckbox from "./CustomCheckbox";

const useStyle = makeStyles(theme => ({
    root: {
        '& .MuiAccordionSummary-expandIcon': {
            transform: 'none',
        }
    },
    accordion: {
        boxShadow: 'none',
    },
    summary: {
        height: '7.1rem',
        padding: 0,
        cursor: 'pointer',
    },
    summaryContent: {
        margin: '12px 0 !important',
    },
    details: {
        padding: 0,
        paddingLeft: '1.8rem',
        display: 'block !important',
        justifyContent: 'center'
    },
    disabled: {
        color: theme.colors.palette.neutral.greyMedium
    }
}));

function CustomAccordion({expanded, title, unmount, onChange, children, disabled, ...props}) {
    const classes = useStyle(props.theme);

    const onExpandChange = (_, value) => {
        if (onChange) {
            onChange(value)
        }
    }

    return (
        <div className={classes.root}>
            <Accordion className={classes.accordion} expanded={expanded} TransitionProps={{unmountOnExit: unmount}}
                       onChange={() => disabled ? () => {
                       } : onExpandChange()}>
                <AccordionSummary classes={{root: classes.summary, content: classes.summaryContent}}
                                  expandIcon={<CustomCheckbox selected={expanded}/>}>
                    <Typography variant={'h6'} className={disabled ? classes.disabled : ''}>
                        {title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    {children}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

CustomAccordion.propTypes = {
    expanded: PropTypes.bool,
    title: PropTypes.string,
    unmount: PropTypes.bool,
    onChange: PropTypes.func,
    children: PropTypes.node,
    disabled: PropTypes.bool,
};

CustomAccordion.defaultProps = {
    unmount: false
};

export default CustomAccordion;