import React, {Component} from 'react';

import {compose} from "redux";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";
import {withTranslation} from "react-i18next";

import withStyles from "@material-ui/core/styles/withStyles";

import Ratings from "./ratings/Ratings";
import HomeScreen from "./home/HomeScreen";
import HomezoneComponent from "./profile/Homezone";
import MeetingPage from "./meetings/MeetingPage";
import NavigationBarDrawer from "./NavigationBarDrawer";
import BookSeatPage from "./workplaceBooking/BookSeatPage";
import NotificationComponent from "./profile/Notification";
import CurrentOccupancy from "./occupancy/OccupancyPage.js";
import ChooseLanguagePage from "./profile/ChooseLanguagePage";
import WorkingGroupPage from "./WorkingGroup/WorkingGroupPage.js";
import RemoteBooking from "./remote/RemoteBooking.js";
import ChangePasswordPage from "./profile/ChangePasswordPage";
import MeetingDetailsPage from "./meetings/MeetingDetailsPage";
import ChooseSeatFloors from "./workplaceBooking/ChooseFloorPage";
import LocationShareSettings from "./profile/LocationShareSettings";
import WorkingGroupCreation from "./WorkingGroup/WorkingGroupCreation";
import ChooseBuildingPage from "./workplaceBooking/ChooseBuildingPage";
import WorkingGroupsOverview from "./WorkingGroup/WorkingGroupsOverview";
import ForgotPasswordPage from "./login/forgotPassword/ForgotPasswordPage";
import NavigationBarHomescreenContent from "./NavigationBarHomescreenContent";
import ForgotPasswordHeaderContent from "./login/NavigationBarDrawerContent.js";
import BackgroundImg from '../common/img/WebBackground.jpg'
import {setScrollPositionBottom} from "../actions/ui-actions";
import {
    meetingOpenRegex,
    navigate,
    PATH_BOOK_RESOURCE,
    PATH_BUILDING_SELECTION,
    PATH_CHOOSE_RESOURCE,
    PATH_CHOOSE_SEAT,
    PATH_CHOOSE_SEAT_SELECT,
    PATH_FLOOR_SELECTION,
    PATH_FORGOT_PASSWORD,
    PATH_HOME,
    PATH_LOGIN,
    PATH_MEETING,
    PATH_MEETING_DETAILS,
    PATH_MEETING_OPEN,
    PATH_MEETING_SCAN,
    PATH_MEETING_SUGGESTIONS,
    PATH_OCCUPANCY,
    PATH_PROFILE,
    PATH_PROFILE_HOMEZONE,
    PATH_PROFILE_INTEGRATIONS,
    PATH_PROFILE_LANGUAGE,
    PATH_PROFILE_NOTIFICATION,
    PATH_PROFILE_PASSWORD,
    PATH_PROFILE_SETTINGS,
    PATH_PROFILE_SHARING,
    PATH_PROFILE_VACATION,
    PATH_PROFILE_WORKING_DAYS,
    PATH_RATINGS,
    PATH_REMOTE,
    PATH_RESET_PASSWORD,
    PATH_RESET_PASSWORD_SUCCESSFULL,
    PATH_RESOURCE_CATEGORY_SELECTION,
    PATH_SCAN_QR,
    PATH_SCAN_SEAT,
    PATH_SOCIAL_BOOKING_SELECT,
    PATH_SPACE_SELECTION,
    PATH_USER_ACHIEVEMENTS,
    PATH_USER_STATISTICS,
    PATH_USER_TIMETRACKINGS,
    PATH_WORKING_GROUPS,
    PATH_WORKING_GROUPS_CREATE,
} from "../common/utils/NavigationUtils";
import ChooseSpacePage from "./workplaceBooking/ChooseSpacePage";
import MeetingSuggestionPage from "./meetings/MeetingSuggestionPage.js";
import WorkplaceSelectionViaColleague from "./workplaceBooking/WorkplaceSelectionViaColleaguePage.js";
import ForgotPasswordNewPasswordPage from "./login/forgotPassword/ForgotPasswordNewPasswordPage.js";
import IntegrationsPage from "./profile/IntegrationsPage.js";
import UserStatisticsPage from "./statistics/UserStatisticsPage.js";
import ProfilePage from "./profile/ProfilePage.js";
import ProfileSettings from "./profile/ProfileSettings.js";
import WorkingDaysPage from "./profile/WorkingDaysPage.js";
import AchievementsPage from "./profile/AchievementsPage.js";
import TimeTrackingOverviewPage from "./profile/timeTracking/TimeTrackingOverviewPage.js";
import {withPersonHOC} from "../common/customHooks/usePerson.js";
import WorkplaceSelectionPage from "./workplaceBooking/WorkplaceSelectionPage.js";
import LoadingIndicator from "../common/elements/LoadingIndicator";
import GoconutSvgLogo from "../common/icons/GoconutSvgLogo.js";
import VacationPage from "./vacation/VacationPage.js";
import LandscapeBlockScreen from "./LandscapeBlockScreen.js";
import {withSentryRouting} from "@sentry/react";
import ResourceBookingPage from "./resources/ResourceBookingDetailsPage.js";
import ChooseResourceCategoryPage from "./resources/ChooseResourceCategoryPage.js";
import ChooseResourcePage from "./resources/ResourceSelectionPage.js";
import QrCodeReader from "./qrCodeReader/QrCodeReader.js";

const styles = theme => ({
    background: {
        width: '100vw',
        overflow: 'hidden',
        overscrollBehavior: 'none',
        backgroundSize: '100% 100%',
        backgroundImage: 'url(' + BackgroundImg + ')',
        height: '100vh',
    },
    appContainer: {
        height: '100%',
        width: '100%',
        maxWidth: theme.gridSizes.maxWidth,
        margin: '0 auto',
        overflow: 'auto',
        overflowY: 'overlay',
        overflowX: 'clip',
        boxShadow: '0 0 12px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: theme.colors.palette.neutral.white,
    },
    container: {
        minHeight: theme.content.height,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    loginContainer: {
        height: 'calc(' + theme.content.height + ' - 160px)',
        width: '100%'
    },
    loadingScreen: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
        alignItems: 'center',
        justifyContent: 'center',
    }
})

// Create Custom Sentry Route component
const SentryRoute = withSentryRouting(Route);

class AppContainer extends Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            headerAnimation: false,
            blockLandscape: false,
        };
    }

    componentDidMount() {
        this.scrollRef = React.createRef()

        window.addEventListener('resize', () => {
            this.setContainerHeight()
            this.onContentScroll()
        })

        window.addEventListener('orientationchange', this.handleLandscapeBlock, true);

        this.handleLandscapeBlock()
        this.setContainerHeight()
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => {
            this.setContainerHeight()
            this.onContentScroll()
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location !== prevProps.location) {
            this.handleHeaderAnimation(false);
        }
    }


    setContainerHeight() {
        // First we get the viewport height, and we multiply it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    onContentScroll() {
        if (this.scrollRef.current) {
            const {scrollTop, clientHeight, scrollHeight} = this.scrollRef.current;

            if (scrollTop <= 30 && this.state.headerAnimation) {
                this.handleHeaderAnimation(false)
            } else if (scrollTop > 30 && !this.state.headerAnimation) {
                this.handleHeaderAnimation(true)
            }

            if (scrollTop + clientHeight >= scrollHeight - 1 && !this.props.scrollBottom) {
                this.props.setScrollPositionBottom(true)
            } else if (scrollTop + clientHeight < scrollHeight - 1 && this.props.scrollBottom) {
                this.props.setScrollPositionBottom(false)
            }
        }
    }

    handleHeaderAnimation = (value) => {
        this.setState({
            headerAnimation: value,
        })
    }

    handleLandscapeBlock = () => {
        if (((window.screen && window.screen.orientation && window.screen.orientation.type.startsWith('landscape')) || (window.orientation && Math.abs(window.orientation) === 90)) &&
            window.screen.availHeight < 450) {
            this.setState({
                blockLandscape: true
            })
        } else {
            this.setState({
                blockLandscape: false
            })
        }

        this.setContainerHeight()
    }

    getNavigationBarContent() {
        const {t} = this.props

        // if (this.props.user && this.props.user.passwordResetRequired)
        //     return null

        if ((PATH_HOME === this.props.location.pathname || meetingOpenRegex.test(this.props.location.pathname)) && this.props.user) {
            return <NavigationBarHomescreenContent/>
        } else if (PATH_LOGIN === this.props.location.pathname) {
            return <ForgotPasswordHeaderContent text={t('welcome')} boldText={t('login_text')}/>
        } else if (PATH_FORGOT_PASSWORD === this.props.location.pathname) {
            return <ForgotPasswordHeaderContent text={t('reset_password')} boldText={t('reset_code_send')}/>
        } else if (PATH_RESET_PASSWORD === this.props.location.pathname) {
            return <ForgotPasswordHeaderContent text={t('reset_password')} boldText={t('create_new_password')}/>
        } else if (PATH_RESET_PASSWORD_SUCCESSFULL === this.props.location.pathname) {
            return <ForgotPasswordHeaderContent text={t('reset_password')}
                                                boldText={t('password_change_successfull')}/>
        } else {
            return null
        }
    }

    isForgotPasswordPath() {
        return PATH_FORGOT_PASSWORD === this.props.location.pathname || PATH_RESET_PASSWORD_SUCCESSFULL === this.props.location.pathname
    }

    isResetPasswordPath() {
        return PATH_RESET_PASSWORD === this.props.location.pathname || this.props.location.pathname.includes(PATH_RESET_PASSWORD)
    }

    render() {
        const {
            classes,
            t,
            userPending,
            userLoaded,
        } = this.props

        if (this.state.blockLandscape) {
            return <LandscapeBlockScreen/>
        }

        if (userPending || !userLoaded) {
            return <div className={classes.loadingScreen}>
                {/*<Snowfall style={{zIndex: 20, height: '100vh'}} speed={[1, 3.5]} wind={[-1, 2.5]}*/}
                {/*          radius={[1, 5]}/>*/}
                <GoconutSvgLogo/>
                <LoadingIndicator/>
            </div>
        }

        return (
            <div className={classes.background} style={{height: 'calc(var(--vh, 1vh) * 100)'}} id={'appContainer'}>
                <div className={classes.appContainer} onScroll={() => this.onContentScroll()} ref={this.scrollRef}>
                    <NavigationBarDrawer isAnimated={!!this.state.headerAnimation}
                                         content={this.getNavigationBarContent()}/>

                    <div className={!this.isForgotPasswordPath() ? classes.container : classes.loginContainer}>

                        {/*{this.props.user.passwordResetRequired ? <InitialPassword/> :*/}

                        <Switch>
                            <SentryRoute path={PATH_FORGOT_PASSWORD} render={(props) =>
                                <ForgotPasswordPage {...props} info={t('code_sent')}
                                                    actionButtonText={t('insert_code')}
                                                    action={() => navigate(this.props.history, PATH_RESET_PASSWORD)}
                                                    cancelAction={() => navigate(this.props.history, PATH_LOGIN)}/>}/>
                            <SentryRoute path={PATH_RESET_PASSWORD} component={ForgotPasswordNewPasswordPage}/>
                            <SentryRoute path={PATH_RESET_PASSWORD_SUCCESSFULL}
                                         render={(props) =>
                                             <ForgotPasswordPage {...props} info={t('new_password_successfull')}
                                                                 actionButtonText={t('back_to_login')}
                                                                 action={() => navigate(this.props.history, PATH_LOGIN)}/>}/>

                            <SentryRoute exact path={PATH_PROFILE} component={ProfilePage}/>

                            <SentryRoute path={PATH_SCAN_QR} component={QrCodeReader}/>

                            <SentryRoute path={PATH_WORKING_GROUPS} component={WorkingGroupsOverview}/>
                            <SentryRoute exact path={PATH_WORKING_GROUPS_CREATE} component={WorkingGroupCreation}/>
                            <SentryRoute path={'/profile/group/:groupId'} component={WorkingGroupPage}/>

                            <SentryRoute path={PATH_USER_STATISTICS} component={UserStatisticsPage}/>
                            <SentryRoute path={PATH_USER_ACHIEVEMENTS} component={AchievementsPage}/>

                            <SentryRoute path={PATH_USER_TIMETRACKINGS} component={TimeTrackingOverviewPage}/>

                            <SentryRoute path={PATH_PROFILE_SHARING} component={LocationShareSettings}/>
                            <SentryRoute path={PATH_PROFILE_HOMEZONE} component={HomezoneComponent}/>
                            <SentryRoute path={PATH_PROFILE_WORKING_DAYS} component={WorkingDaysPage}/>
                            <SentryRoute path={PATH_PROFILE_VACATION} component={VacationPage}/>

                            <SentryRoute path={PATH_PROFILE_LANGUAGE} component={ChooseLanguagePage}/>
                            <SentryRoute path={PATH_PROFILE_INTEGRATIONS} component={IntegrationsPage}/>
                            <SentryRoute path={PATH_PROFILE_PASSWORD} component={ChangePasswordPage}/>
                            <SentryRoute path={PATH_PROFILE_NOTIFICATION} component={NotificationComponent}/>
                            <SentryRoute path={PATH_PROFILE_SETTINGS} component={ProfileSettings}/>


                            <SentryRoute path={PATH_SCAN_SEAT + "/:workplaceId"}
                                         component={WorkplaceSelectionPage}/>
                            <SentryRoute path={PATH_CHOOSE_SEAT_SELECT} component={WorkplaceSelectionPage}/>
                            <SentryRoute path={PATH_CHOOSE_SEAT + "/:selection"} component={BookSeatPage}/>
                            <SentryRoute path={PATH_CHOOSE_SEAT} component={BookSeatPage}/>

                            <SentryRoute path={PATH_BUILDING_SELECTION} component={ChooseBuildingPage}/>
                            <SentryRoute path={PATH_FLOOR_SELECTION} component={ChooseSeatFloors}/>
                            <SentryRoute path={PATH_SPACE_SELECTION} component={ChooseSpacePage}/>

                            <SentryRoute path={PATH_SOCIAL_BOOKING_SELECT}
                                         component={WorkplaceSelectionViaColleague}/>

                            <SentryRoute path={PATH_RESOURCE_CATEGORY_SELECTION}
                                         component={ChooseResourceCategoryPage}/>
                            <SentryRoute path={PATH_CHOOSE_RESOURCE} component={ChooseResourcePage}/>
                            <SentryRoute path={PATH_BOOK_RESOURCE} component={ResourceBookingPage}/>

                            <SentryRoute exact path={PATH_MEETING} component={MeetingPage}/>
                            <SentryRoute path={PATH_MEETING_DETAILS} component={MeetingDetailsPage}/>
                            <SentryRoute path={PATH_MEETING_SUGGESTIONS} component={MeetingSuggestionPage}/>
                            <SentryRoute path={PATH_MEETING_SCAN + '/:spaceId'} component={WorkplaceSelectionPage}/>

                            <SentryRoute exact path={PATH_REMOTE} component={RemoteBooking}/>

                            <SentryRoute path={PATH_RATINGS} component={Ratings}/>
                            <SentryRoute path={PATH_OCCUPANCY} component={CurrentOccupancy}/>

                            <SentryRoute path={PATH_MEETING_OPEN}
                                         render={(props) => <HomeScreen {...props}
                                                                        isAnimated={this.state.headerAnimation}/>}/>
                            <SentryRoute path={'/'}
                                         render={(props) => <HomeScreen {...props}
                                                                        isAnimated={this.state.headerAnimation}/>}/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        userId: state.user.person.id,
        userPending: state.user.userPending,
        userLoaded: state.user.userLoaded,
        forgotPasswordPending: state.user.forgotPasswordPending,
        scrollBottom: state.uiState.scrollPositionBottom,
    }
}

const mapDispatchToProps = {
    setScrollPositionBottom: setScrollPositionBottom,
}

export default compose(withStyles(styles), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(withPersonHOC(AppContainer)))
