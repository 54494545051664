import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import {isEqual} from "lodash";
import {connect} from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import useTheme from "@material-ui/core/styles/useTheme";
import {makeStyles, Tooltip, Typography} from "@material-ui/core";

import AnonymUser from "../img/icons-map-anonym-user@3x.png";
import EmailIcon from "../img/EmailIcon.png";

import {getImageForAvatar} from "../utils/ActivityUtils";
import {getProfilePictureOfCoworker} from "../../actions/occupancy-actions";
import {
    AVAILABILITY_STATUS_ABSENT,
    AVAILABILITY_STATUS_HOMEOFFICE,
    AVAILABILITY_STATUS_MEETING,
    AVAILABILITY_STATUS_OFFLINE,
    AVAILABILITY_STATUS_ONLINE,
    AVAILABILITY_STATUS_OUTOFOFFICE,
    TYPE_OF_ACTIVITY_CALLS,
    TYPE_OF_ACTIVITY_CONCENTRATE,
    TYPE_OF_ACTIVITY_CREATIVE,
    TYPE_OF_ACTIVITY_TALK,
    TYPE_OF_ACTIVITY_UNDEFINED,
    TYPE_OF_BOOKING_HOMEOFFICE,
    TYPE_OF_BOOKING_OUT_OF_OFFICE,
    TYPE_OF_BOOKING_VACATION
} from "../utils/NameUtils.js";
import {drawingColor} from "../../components/drawingTool/drawing/drawConstants.js";
import theme from "../../theme.js";
import {useTranslation} from "react-i18next";
import {getDegreeOfTimeInCircle} from "../utils/DrawingUtils.js";
import {
    getTodayAtDefaultEndOfWorkingDay,
    getTodayAtDefaultStartOfWorkingDay,
    sortAssignments
} from "../utils/TimeUtils.js";
import {usePrevious} from "../customHooks/usePrevious.js";

const useStyle = (props, workplace, gradient) => makeStyles(theme => ({
    root: {
        width: props.size ? props.size > 100 ? props.size + 5 : props.size : '48px',
        height: props.size ? props.size > 100 ? props.size + 5 : props.size : '48px',
        position: 'relative',
        display: 'flex',
        borderRadius: '48px',
        '& .MuiAvatar-root ': {
            fontWeight: '400'
        },
    },
    profilePictureAvatar: {
        backgroundColor: 'none',
        position: 'absolute',
    },
    profileImage: {
        height: '100%',
        width: '100%'
    },
    avatarBadge: {
        width: '16px',
        maxWidth: '16px',
        height: '16px',
        right: 0,
        bottom: 0,
        margin: 0,
        position: 'absolute',
        borderRadius: '50%',
        zIndex: 1,
        border: '3px solid',
        borderColor: theme.colors.palette.neutral.white,
        boxSizing: 'border-box'
    },
    circleBorderFilled: {
        position: 'relative',
        textAlign: 'center',
        width: props.size ? props.size > 100 ? props.size + 5 : props.size : '54px',
        height: props.size ? props.size > 100 ? props.size + 5 : props.size : '54px',
        borderRadius: '100%',
        background: gradient//'conic-gradient(' + theme.colors.palette.neutral.greyMedium + ' ' + startPercentage + 'deg, ' + getColorByActivity(workplace?.activityBestMatch) + ' ' + startPercentage + 'deg, ' + getColorByActivity(workplace?.activityBestMatch) + ' ' + endPercentage + 'deg, ' + theme.colors.palette.neutral.greyMedium + ' 0deg)',
    },
}))


export function getColorByActivity(activity) {
    switch (activity) {
        case TYPE_OF_ACTIVITY_CREATIVE:
            return drawingColor.workplaceCreative
        case TYPE_OF_ACTIVITY_TALK:
            return drawingColor.workplaceTalk
        case TYPE_OF_ACTIVITY_CALLS:
            return drawingColor.workplaceCalls
        case TYPE_OF_ACTIVITY_CONCENTRATE:
            return drawingColor.workplaceConcentrate
        case TYPE_OF_BOOKING_HOMEOFFICE:
            return drawingColor.workplaceHomeoffice
        case TYPE_OF_BOOKING_OUT_OF_OFFICE:
            return drawingColor.workplaceOutOfOffice
        case TYPE_OF_BOOKING_VACATION:
            return drawingColor.workplaceVacation
        case TYPE_OF_ACTIVITY_UNDEFINED:
        default:
            return drawingColor.workplaceUndefined
    }
}

export function getAvailabilityStatusColor(status) {
    switch (status) {
        case AVAILABILITY_STATUS_ONLINE:
        case AVAILABILITY_STATUS_HOMEOFFICE:
            return theme.colors.palette.status.online
        case AVAILABILITY_STATUS_ABSENT:
        case AVAILABILITY_STATUS_OUTOFOFFICE:
            return theme.colors.palette.status.absent
        case AVAILABILITY_STATUS_MEETING:
            return theme.colors.palette.status.meeting
        case AVAILABILITY_STATUS_OFFLINE:
        default:
            return theme.colors.palette.status.offline
    }
}


export function getInitials(person) {
    if (!person || !person.name || !person.surname)
        return
    return person.name.substring(0, 1).toUpperCase() + person.surname.substring(0, 1).toUpperCase()
}

function AvatarWithOccupancy({
                                 workplace,
                                 assignments,
                                 profilePictures,
                                 getProfilePicture,
                                 colorOfBackground,
                                 showOccupancy,
                                 initialsOnly,
                                 person,
                                 className,
                                 onClick,
                                 hideAvailabilityStatus,
                                 preventInitials,
                                 showFrom,
                                 showUntil,
                                 selectedDate,
                                 ...props
                             }) {
    const theme = useTheme()
    const {t} = useTranslation()

    const timeSettings = localStorage.getItem('occupancyTimeSettings')
        ? JSON.parse(localStorage.getItem('occupancyTimeSettings'))
        : {startDate: getTodayAtDefaultStartOfWorkingDay(), endDate: getTodayAtDefaultEndOfWorkingDay()}

    // position needed for classes variable
    const getGradient = () => {
        let gradient = 'conic-gradient(from 0deg, '

        for (let a of sortAssignments(assignments)) {
            const startPercentage = getDegreeOfTimeInCircle(showFrom ?? timeSettings.startDate, showUntil ?? timeSettings.endDate, a.timePeriod?.startDate, selectedDate)
            const endPercentage = getDegreeOfTimeInCircle(showFrom ?? timeSettings.startDate, showUntil ?? timeSettings.endDate, a?.timePeriod?.endDate, selectedDate)

            gradient += theme.colors.palette.neutral.greyMedium + ' ' + startPercentage + 'deg, '
            gradient += startPercentage + 'deg, ' + getColorByActivity(a.workplace?.activityBestMatch) + ' '
                + startPercentage + 'deg, '
                + getColorByActivity(a.workplace?.activityBestMatch) + ' ' + endPercentage + 'deg, '
                + theme.colors.palette.neutral.greyMedium + ' ' + endPercentage + 'deg, '
        }

        gradient += theme.colors.palette.neutral.greyMedium + ' 0deg)'
        return gradient

    }

    const classes = useStyle(props, workplace, getGradient())(theme);
    const [image, setImage] = useState(null);
    const personToUse = person ? person : (assignments && assignments.length) ? assignments[0].person : null
    const personStatus = personToUse?.availabilityStatus
    const prevLoadingArray = usePrevious(props.pictureLoadingArray || [])

    const backgroundColor = colorOfBackground
        ? colorOfBackground
        : showOccupancy
            ? theme.colors.palette.neutral.darkMain
            : personToUse?.color
                ? personToUse.color
                : theme.colors.palette.neutral.darkMain
    const color = theme.palette.getContrastText(backgroundColor)

    useEffect(() => {
        if (personToUse && personToUse.profilePictureId !== null && !initialsOnly && personToUse.id) {
            checkAndGetImage()
        } else if ((!assignments || !assignments.length) && workplace && !initialsOnly) {
            setImage(getImageForAvatar(null, workplace.activityBestMatch))
        } else if (preventInitials || (personToUse && personToUse.id === -1 && !initialsOnly)) {
            setImage(getImageForAvatar(AnonymUser))
        } else if (!personToUse.id && personToUse.email) {
            setImage(getImageForAvatar(EmailIcon, null, 24))
        } else if (image !== null) {
            setImage(null)
        }
        // eslint-disable-next-line
    }, [personToUse]);

    useEffect(() => {
        if (image === null && prevLoadingArray && prevLoadingArray.find(item => Number(item) === Number(personToUse.id))) {
            findAndSetProfilePicture(personToUse.id)
        }
        // eslint-disable-next-line
    }, [props.pictureLoadingArray]);


    function checkAndGetImage() {
        const personId = personToUse.id
        if (!findAndSetProfilePicture(personId) && !props.pictureLoadingArray.find(val => val === personId)) {
            getProfilePicture(personId)
        }
    }

    function findAndSetProfilePicture(personId) {
        let profilePictureIndex = profilePictures.findIndex(element => isEqual(personId.toString(), element.personId.toString()))

        if (profilePictureIndex > -1) {
            let profilePicture = profilePictures[profilePictureIndex].profilePicture

            profilePicture = profilePicture.startsWith('data:image/png;base64,')
                ? profilePicture
                : 'data:image/png;base64,' + profilePicture

            setImage(getImageForAvatar(profilePicture))
            return true
        }
        return false
    }

    return (
        <div className={showOccupancy && classes.circleBorderFilled}>
            <div className={clsx(classes.root, className)} onClick={onClick}>
                {personToUse && !hideAvailabilityStatus &&
                    <Tooltip title={personStatus
                        ? personStatus.customText
                            ? personStatus.customText
                            : personStatus.statusText
                        : t('offline')}
                             enterTouchDelay={0} leaveDelay={1000}>
                        <div className={classes.avatarBadge}
                             style={{
                                 backgroundColor: personToUse.availabilityStatus
                                     ? getAvailabilityStatusColor(personToUse.availabilityStatus.typeOfAvailabilityStatus)
                                     : getAvailabilityStatusColor()
                             }}/>
                    </Tooltip>
                }
                <Avatar
                    className={classes.profilePictureAvatar}
                    style={{
                        width: showOccupancy ? props.size - 8 + 'px' : props.size + 'px',
                        height: showOccupancy ? props.size - 8 + 'px' : props.size + 'px',
                        margin: showOccupancy ? props.size > 100 ? 7 : '4px' : 0,
                        backgroundColor: image && !initialsOnly ? '' : backgroundColor,
                    }}
                >
                    {image !== null && !initialsOnly
                        ? image
                        : <Typography style={{
                            fontSize: props.size ? props.size > 48 ? props.size / 3 : !showOccupancy && props.size / 2 : '20px',
                            color: color
                        }}>
                            {getInitials(personToUse)}
                        </Typography>
                    }
                </Avatar>
            </div>
        </div>
    )
}

AvatarWithOccupancy.propTypes = {
    size: PropTypes.number,
    //to determine occupancy
    assignments: PropTypes.array,
    //to get profilePicture or Initials
    person: PropTypes.any,
    //whether to show the occupancy
    showOccupancy: PropTypes.bool,
    //if an activityIcon should be displayed
    workplace: PropTypes.any,
    //overrides the backgroundColor
    colorOfBackground: PropTypes.string,
    //Function triggered on click
    onClick: PropTypes.func,
    //Prevent loading and displaying of profilePicture
    initialsOnly: PropTypes.bool,
    //Hide the AvailabilityStatus of th person
    hideAvailabilityStatus: PropTypes.bool,
    //If no profile picture provided -> don't show initials but anonym user pic
    preventInitials: PropTypes.bool,
    //Overrides the default startTime at the top of the occupancy-circle
    showFrom: PropTypes.any,
    //Overrides the default endTime at the top of the occupancy-circle
    showUntil: PropTypes.any,
}

AvatarWithOccupancy.defaultProps = {
    size: 48,
    preventInitials: false
}

let mapStateToProps = (state) => {
    return {
        profilePictures: state.occupancy.profilePicturesBase64,
        pictureLoadingArray: state.occupancy.getProfilePicturesPending,
        selectedDate: state.assignmentBuilder.selectedDate,
    }
}

let mapDispatchToProps = {
    getProfilePicture: getProfilePictureOfCoworker,
}

export default connect(mapStateToProps, mapDispatchToProps)(AvatarWithOccupancy);
