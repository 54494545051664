const initialState = {
    terminal: null,
    announcements: [],
    statistics: new Map(),
    buildings: [],
    floor: {},
    floorplan: null,
    assignments: [],
    selectedTerminal: null,
}

function terminalReducer(state = initialState, action) {

    switch (action.type) {

        case 'TERMINAL_GET_TERMINAL_FULFILLED': {
            return {
                ...state,
                terminal: action.payload.data,
            }
        }

        case 'TERMINAL_GET_TERMINAL_REJECTED': {
            return {
                ...state,
                terminal: null,
            }

        }

        case 'TERMINAL_GET_ANNOUNCEMENTS_FULFILLED': {
            return {
                ...state,
                announcements: action.payload.data,
            }
        }

        case 'TERMINAL_GET_STATISTICS_FULFILLED': {
            return {
                ...state,
                statistics: action.payload.data
            }
        }

        case 'TERMINAL_GET_BUILDING_NAMES_FULFILLED': {
            return {
                ...state,
                buildings: action.payload.data,
            }
        }

        case 'TERMINAL_GET_FLOOR_FULFILLED': {
            return {
                ...state,
                floor: action.payload.data,
            }
        }

        case 'TERMINAL_GET_FLOOR_PLAN_FULFILLED': {

            let floorplan = action.payload.data

            floorplan.image = 'data:image/png;base64,' + floorplan.image

            return {
                ...state,
                floorplan: floorplan
            }
        }

        case 'TERMINAL_GET_FLOOR_ASSIGNMENTS_FULFILLED': {
            return {
                ...state,
                assignments: action.payload.data.data,
            }
        }

        case 'TERMINAL_SET_SELECTED':
            return {
                ...state,
                selectedTerminal: action.payload,
            }

        default:
            return {...state}
    }
}

export default terminalReducer