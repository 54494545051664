import axios from 'axios';

export function createWorkplaces(buildingId, floorId, workplaces) {
    if (!workplaces.length) {
        workplaces = [workplaces]
    }

    return {
        type: 'CREATE_WORKPLACES',
        payload: axios.post(`/api/building/${buildingId}/floor/${floorId}/space/-1/workplace`, workplaces)
    }
}

export function updateWorkplaces(buildingId, floorId, workplaces) {
    if (!workplaces.length) {
        workplaces = [workplaces]
    }
    return {
        type: 'UPDATE_WORKPLACES',
        payload: axios.patch(`/api/building/${buildingId}/floor/${floorId}/space/${-1}/workplace`, workplaces)
    }
}

export function deleteWorkplaces(buildingId, floorId, workplaceIds) {
    if (!workplaceIds.length) {
        workplaceIds = [workplaceIds]
    }
    return {
        type: 'DELETE_WORKPLACES',
        payload: axios.delete(`/api/building/${buildingId}/floor/${floorId}/space/${null}/workplace`, {data: workplaceIds})
    }
}

export function changeWorkplaceStatus(buildingId, floorId, spaceId, workplaceId, status) {

    return {
        type: 'CHANGE_WORKPLACE_STATUS',
        payload: axios.post(`/api/building/${buildingId}/floor/${floorId}/space/${spaceId}/workplace/${workplaceId}/status`, {}, {
            params: {
                status: status
            }
        })
    }
}

export function assignWorkplaceToPerson(personId, assignment, workingGroupId, deleteExistingAssignmentsAtDate) {
    return {
        type: 'ASSIGN_WORKPLACE_TO_PERSON',
        payload: axios.post(`/api/person/${personId}/workplaceAssignment`, assignment,
            {
                params: {
                    workingGroupId: workingGroupId,
                    deleteExistingAssignmentsAtDate: deleteExistingAssignmentsAtDate
                },
            })
    }
}

export function loadWorkplaceAssignmentsAdmin(workplaceId, startDate, endDate) {
    return {
        type: 'LOAD_ASSIGNMENTS_OF_WORKPLACE',
        payload: axios.get(`/api/building/-1/floor/-1/space/-1/workplace/${workplaceId}/assignments`, {
            params: {
                startDate: startDate,
                endDate: endDate,
            }
        })
    }
}

export function updateWorkplaceAssignment(personId, assignmentId, assignment) {
    return {
        type: 'UPDATE_WORKPLACE_ASSIGNMENT',
        payload: axios.patch(`/api/person/${personId}/workplaceAssignment/${assignmentId}`, assignment)
    }
}

export function getCoworkersAssignmentsAtDate(personId, start, end, floorId, loadAnonymUsers, loadHomeoffice) {
    return {
        type: 'GET_COWORKERS_ASSIGNMENTS_AT_DATE',
        payload: axios.get(`/api/person/${personId}/coworkersAssignments/floor/${floorId}`, {
            params: {
                start: start,
                end: end,
                loadAnonymUsers: loadAnonymUsers,
                loadHomeoffice: loadHomeoffice
            }
        })
    }
}

export function getWorkplaces(buildingId, floorId, spaceId, startDate, endDate) {
    return {
        type: 'GET_WORKPLACES',
        payload: axios.get(`/api/building/${buildingId}/floor/${floorId}/space/${spaceId}/workplace`,
            {
                params: {
                    startDate: startDate,
                    endDate: endDate
                }
            })
    }
}

export function getWorkplace(workplaceId, startDate, endDate) {
    return {
        type: 'GET_WORKPLACE',
        payload: axios.get(`/api/building/-1/floor/-1/space/-1/workplace/${workplaceId}`,
            {
                params: {
                    startDate: startDate,
                    endDate: endDate
                }
            })
    }
}

export function getAvailabilityStatusOfWorkplace(buildingId, floorId, spaceId, workplaceId, startDate, endDate) {
    return {
        type: 'GET_AVAILABILITY_STATUS_WORKPLACE',
        payload: axios.get(`/api/building/${buildingId}/floor/${floorId}/space/${spaceId}/workplace/${workplaceId}/available`,
            {
                params: {
                    startDate: startDate,
                    endDate: endDate
                }
            })
    }
}

export function selectFavoriteWorkplace(workplace) {
    return {
        type: 'SET_FAVORED_WORKPLACE',
        payload: workplace,
    }
}

export function setSelectedActivity(activity) {
    return {
        type: 'SET_SELECTED_ACTIVITY',
        payload: activity,
    }
}

export function setTimePeriodForAssignment(timePeriod) {
    return {
        type: 'SET_ASSIGNMENT_TIME_PERIOD',
        payload: timePeriod,
    }
}

export function setSelectedDate(date) {
    return {
        type: 'SET_SELECTED_DATE',
        payload: date,
    }
}

export function getUnratedAssignmentsFromPerson(personId) {
    return {
        type: 'GET_UNRATED_ASSIGNMENTS_FROM_PERSON',
        payload: axios.get(`/api/person/${personId}/workplaceAssignment/unrated`)
    }
}

export function rateWorkplace(personId, assignment) {
    return {
        type: 'RATE_WORKPLACE',
        payload: axios.post(`/api/person/${personId}/rating`, assignment)
    }
}

export function setAssignments(assignments) {
    return {
        type: 'SET_ASSIGNMENTS',
        payload: assignments
    }
}