import React from 'react';
import PropTypes from "prop-types";

import {useTranslation} from "react-i18next";

import Dialog from "@material-ui/core/Dialog";
import useTheme from "@material-ui/core/styles/useTheme";
import {DialogActions, DialogContent, DialogTitle, makeStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AdminButton from "./AdminButton.js";

const useStyle = makeStyles(theme => ({
    modal: {
        // padding: theme.innerGap,
        borderRadius: '6px',
        maxWidth: '42rem',
        minHeight: '35rem',
        maxHeight: '65rem',
        boxShadow: 'none',
    },
    header: {
        padding: theme.modalPanel.padding,
    },
    content: {
        padding: theme.modalPanel.padding,
    },
    actions: {
        padding: theme.modalPanel.padding,
    },
    text: {
        color: theme.colors.palette.neutral.darkMain,
        textAlign: 'start'
    },
}));

function SimpleModal({
                         open,
                         title,
                         onSubmit,
                         submitText,
                         form,
                         onClose,
                         onThirdOption,
                         thirdOptionText,
                         confirmDisabled,
                         children
                     }) {

    const theme = useTheme()
    const {t} = useTranslation();
    const classes = useStyle(theme);

    const handleClose = () => {
        onClose()
    }

    const handleSubmit = () => {
        onSubmit()
        if (!form)
            onClose()
    }

    const handleThirdOption = () => {
        onThirdOption()
        onClose()
    }

    return (
        <Dialog open={open} scroll={'paper'} onClose={handleClose} fullWidth maxWidth={'md'}
                onClick={(e) => e.stopPropagation()}
                classes={{
                    paper: classes.modal,
                }}>

            <DialogTitle disableTypography className={classes.header}>
                <Typography variant={'h5'}>{title}</Typography>
            </DialogTitle>

            <DialogContent className={classes.content}>
                {children}
            </DialogContent>

            <DialogActions className={classes.actions}>
                <AdminButton onClick={handleClose} text={t('cancel')}
                             textColor={theme.colors.palette.neutral.black}
                />

                {onThirdOption && <AdminButton onClick={handleThirdOption} secondary text={thirdOptionText}/>}

                <AdminButton
                    primary
                    onClick={handleSubmit}
                    type={form ? 'submit' : 'button'} form={form ?? null}
                    disabled={confirmDisabled}
                    text={submitText || t('confirm')}
                />
            </DialogActions>

        </Dialog>
    );
}

SimpleModal.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onThirdOption: PropTypes.func,
    thirdOptionText: PropTypes.string,
    submitText: PropTypes.string,
    //the id of a form (child component) which should be submitted
    form: PropTypes.string,
    confirmDisabled: PropTypes.bool,
}

SimpleModal.defaultProps = {
    open: false,
    form: '',
    confirmDisabled: false,
}

export default SimpleModal
