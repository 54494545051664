import React from 'react';

import clsx from "clsx";
import moment from "moment";
import * as DOMPurify from 'dompurify';

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {Typography} from "@material-ui/core";

import {getTimePeriodAsString} from "../../common/utils/TimeUtils.js";
import {
    ATTENDANCE_STATUS_ATTENDING_IN_PERSON,
    ATTENDANCE_STATUS_ATTENDING_ONLINE,
    ATTENDANCE_STATUS_CANCELED
} from "../../common/utils/NameUtils.js";
import {
    getMeetingLocationAsString,
    isAttendanceCanceled,
    isAttendingInPerson,
    isAttendingOnline,
    isInitiator
} from "../../common/utils/AssignmentUtils";
import PropTypes from "prop-types";
import {setAttendanceStatus} from "../../actions/user-action.js";
import withStyles from "@material-ui/core/styles/withStyles";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import MeetingParticipants from "./MeetingParticipants.js";

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1.8rem'
    },
    skinMain: {
        color: theme.colors.palette.corporate.skinMain,
    },
    subContentGapContainer: {
        display: 'flex',
        alignItems: 'start',
        textAlign: 'left',
        flexDirection: 'column',
        gap: theme.innerSmallGap
    },
    button: {
        textTransform: 'capitalize',
    },
    title: {
        fontWeight: 'normal',
    },
    selectedGreen: {
        borderRadius: '12px',
        border: 'solid 1px',
        borderColor: '#60bf82',
        backgroundColor: 'rgba(96, 191, 130, 0.5)',
        color: '#60bf82',
        '&:hover': {
            backgroundColor: 'rgba(96, 191, 130, 0.5)',
        }
    },
    selectedRed: {
        borderRadius: '12px',
        border: 'solid 1px',
        borderColor: '#f74e44',
        backgroundColor: 'rgba(247, 78, 68, 0.5)',
        color: '#f74e44',
        '&:hover': {
            backgroundColor: 'rgba(247, 78, 68, 0.5)',
        }
    },
})

function MeetingInformationContent({
                                       classes,
                                       t,
                                       assignment,
                                       theme,
                                       userId,
                                       setAttendanceStatus,
                                       onEdit,
                                       showReplyOptions,
                                       additionalElement,
                                       className,
                                       ...props
                                   }) {

    function setAttendanceStatusOfPerson(attendanceStatus) {
        setAttendanceStatus(attendanceStatus, userId, assignment.id)
    }

    if (!assignment)
        return

    return (
        <div className={clsx(classes.root, className)}>
            <div className={classes.subContentGapContainer}>
                <Typography variant={"h3"} className={classes.title}>
                    {assignment.title ?? t('general.noInformation')}
                </Typography>
                <div>
                    <Typography variant={"body1"} className={classes.skinMain} align={'left'}>
                        {getTimePeriodAsString(assignment.timePeriod)}
                    </Typography>
                    <Typography variant={"body1"} className={classes.skinMain}>
                        {assignment ? moment(assignment.timePeriod.startDate).format("dd, DD.MM.YYYY") : null}
                    </Typography>
                </div>
                <Typography variant={"h6"}>
                    {getMeetingLocationAsString(assignment, t)}
                </Typography>
            </div>

            <Divider/>

            {assignment.description &&
                <div className={classes.subContentGapContainer}>
                    <Typography variant={"h6"}>{t('description')}</Typography>
                    <div className={'innerHtmlStyles'}
                         dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(assignment.description)}}></div>
                </div>}

            {assignment.description && <Divider/>}

            {assignment.onlineLink &&
                <div className={classes.subContentGapContainer}>
                    <Typography variant={"h6"}>{t('meeting_link')}</Typography>
                    <a href={`${assignment.onlineLink}`} target={'_blank'} rel={'noopener noreferrer'}>
                        <Typography variant={"body1"} className={classes.skinMain}>
                            {assignment.onlineLink}
                        </Typography>
                    </a>
                </div>}

            {assignment.onlineLink && <Divider/>}

            {showReplyOptions
                ? !isInitiator(assignment, userId)
                    ? <div className={classes.subContentGapContainer}>
                        <Typography variant={"h6"}>{t('participate')}</Typography>
                        <div>
                            <Button
                                className={clsx(classes.button, isAttendingInPerson(assignment, userId) && classes.selectedGreen)}
                                onClick={() => setAttendanceStatusOfPerson(ATTENDANCE_STATUS_ATTENDING_IN_PERSON)}>
                                <Typography variant={'body1'}>{t('yes')}</Typography>
                            </Button>
                            {!assignment.onlineOnly ?
                                <Button
                                    className={clsx(classes.button, isAttendingOnline(assignment, userId) && classes.selectedGreen)}
                                    onClick={() => setAttendanceStatusOfPerson(ATTENDANCE_STATUS_ATTENDING_ONLINE)}>
                                    <Typography variant={'body1'}>{t('online')}</Typography>
                                </Button> : null}
                            <Button className={clsx(classes.button,
                                isAttendanceCanceled(assignment, userId) && classes.selectedRed)}
                                    onClick={() => setAttendanceStatusOfPerson(ATTENDANCE_STATUS_CANCELED)}
                            >
                                <Typography variant={'body1'}>
                                    {t('no')}
                                </Typography>
                            </Button>
                        </div>
                    </div>
                    : <div className={classes.subContentGapContainer}>
                        <Typography variant={"h6"}>{t('participate')}</Typography>
                        <Typography>{t('meeting_creator')}</Typography>
                    </div>
                : null}

            {showReplyOptions && assignment.attendances && <Divider/>}

            <MeetingParticipants attendances={assignment.attendances}
                                 externalAttendances={assignment.externalAttendances}/>

            {additionalElement && <Divider/>}

            {additionalElement}
        </div>
    )
}

MeetingInformationContent.propTypes = {
    assignment: PropTypes.any.isRequired,
    showReplyOptions: PropTypes.bool,
    additionalElement: PropTypes.element
}

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
    }
}

const mapDispatchToProps = {
    setAttendanceStatus: setAttendanceStatus,
}

export default compose(withStyles(styles, {withTheme: true}), withTranslation())(connect(mapStateToProps,
    mapDispatchToProps)(MeetingInformationContent))
