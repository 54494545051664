import * as React from "react"
import PropTypes from 'prop-types'

function AddPeopleSvgIcon({color, width, height, ...props}) {
    return (
        <svg
            width={width || '100%'}
            height={height || '100%'}
            viewBox="0 0 40 40"
            {...props}
        >
            <g
                transform="translate(8 9)"
                stroke={color}
                strokeWidth={1.5}
                fill="none"
                fillRule="evenodd"
                strokeLinejoin="round"
            >
                <circle cx={8} cy={5} r={5}/>
                <path d="M0 23v-4a6 6 0 016-6h4a6 6 0 016 6v4h0m0-17h10m-5-5v10"/>
            </g>
        </svg>
    )
}

AddPeopleSvgIcon.propTypes = {
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

AddPeopleSvgIcon.defaultProps = {
    color: '#333',
};


export default AddPeopleSvgIcon
