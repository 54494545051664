import axios from 'axios';

export function getNumberOfAssignmentsPerWorkplace(buildingId, floorId, startDate, endDate) {
    let spaceId = -1
    let workplaceId = -1

    return {
        type: 'GET_NUMBER_OF_OCCUPIED_SEATS_PER_WORKPLACE',
        payload: axios.get(`/api/building/${buildingId}/floor/${floorId}/space/${spaceId}/workplace/${workplaceId}/assignmentsOfWorkplaces`,
            {
                params: {
                    startDate: startDate,
                    endDate: endDate
                }
            })
    }
}

export function getRatingDeviationGroupedByWorkplace(buildingId, floorId, startDate, endDate, activityAttribute) {
    let spaceId = -1
    let workplaceId = -1

    return {
        type: 'GET_RATING_DEVIATIONS_PER_WORKPLACE',
        payload: axios.get(`/api/building/${buildingId}/floor/${floorId}/space/${spaceId}/workplace/${workplaceId}/ratingDeviationOfWorkplaces`,
            {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    activityAttribute: activityAttribute
                }
            })
    }
}

export function getNumberOfHomeOfficeSeats(startDate, endDate) {
    return {
        type: 'GET_NUMBER_OF_HOMEOFFICE_SEATS',
        payload: axios.get(`/api/statistics/homeoffice`,
            {
                params: {
                    startDate: startDate,
                    endDate: endDate
                }
            })
    }
}

export function getActivityStatistics(startDate, endDate, buildingId = -1, floorId = -1) {
    return {
        type: 'GET_ACTIVITY_STATISTICS',
        payload: axios.get(`/api/statistics/activities`,
            {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    buildingId: buildingId,
                    floorId: floorId,
                }
            })
    }
}

export function getUserActivityStatistics(startDate, endDate) {
    return {
        type: 'GET_ACTIVITY_STATISTICS',
        payload: axios.get(`/api/statistics/user/activities`,
            {
                params: {
                    startDate: startDate,
                    endDate: endDate
                }
            })
    }
}

export function getUserDistributionStatisticsAllTime() {
    return {
        type: 'GET_USER_DISTRIBUTION_STATISTICS_ALL_TIME',
        payload: axios.get(`/api/statistics/user/distribution`)
    }
}

export function getUserDistributionStatistics(startDate, endDate) {
    return {
        type: 'GET_USER_DISTRIBUTION_STATISTICS',
        payload: axios.get(`/api/statistics/user/distribution`,
            {
                params: {
                    startDate: startDate,
                    endDate: endDate
                }
            })
    }
}

export function getUserMeetingStatistics(startDate, endDate) {
    return {
        type: 'GET_USER_MEETING_STATISTICS',
        payload: axios.get(`/api/statistics/user/meetings`,
            {
                params: {
                    startDate: startDate,
                    endDate: endDate
                }
            })
    }
}

export function getOccupancyStatistics(startDate, endDate, buildingId, floorId) {
    return {
        type: 'GET_STATISTICS_BUILDING_OCCUPANCY',
        payload: axios.get(`/api/statistics/occupancy`,
            {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    floorId: floorId,
                    buildingId: buildingId,
                }
            }),
    }
}

export function getDistributionStatistics(startDate, endDate) {
    return (dispatch, getState) => {
        const {getDistributionPending} = getState().dashboard;

        if (getDistributionPending !== startDate.toLocaleDateString() + ' - ' + endDate.toLocaleDateString()) {
            dispatch(getDistributionApiCall(startDate, endDate))
        }
    }
}

function getDistributionApiCall(startDate, endDate) {
    return {
        type: 'GET_DISTRIBUTION_STATISTICS',
        meta: startDate.toLocaleDateString() + ' - ' + endDate.toLocaleDateString(),
        payload: axios.get(`/api/statistics/distribution`,
            {
                params: {
                    startDate: startDate,
                    endDate: endDate
                }
            }),
    }
}

export function getSustainabilityStatistics(startDate, endDate) {
    return {
        type: 'GET_SUSTAINABILITY_STATISTICS',
        payload: axios.get(`/api/statistics/sustainability`,
            {
                params: {
                    startDate: startDate,
                    endDate: endDate
                }
            })
    }
}

export function getNrMeetingsStatistics(startDate, endDate, buildingId = -1, floorId = -1) {
    return {
        type: 'GET_NR_MEETINGS_STATISTICS',
        payload: axios.get(`/api/statistics/nrMeetings`,
            {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    buildingId: buildingId,
                    floorId: floorId,
                }
            })
    }
}

export function getAverageAttendeesStatistics(startDate, endDate) {
    return {
        type: 'GET_AVERAGE_ATTENDEES_STATISTICS',
        payload: axios.get('/api/statistics/nrOnlineOfflineMeetings',
            {
                params: {
                    startDate: startDate,
                    endDate: endDate
                }
            })
    }
}

export function getGamificationPointUtils() {
    return {
        type: 'GET_GAMIFICATION_POINT_UTILS',
        payload: axios.get('/api/statistics/gamificationPointUtils')
    }
}