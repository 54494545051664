import React from "react";

import {useTranslation} from "react-i18next";

import useTheme from "@material-ui/core/styles/useTheme";
import {makeStyles, Typography} from "@material-ui/core";

import ListEmptyStateSvg from "../../common/icons/ListEmptyStateSvg.js";


const useStyle = makeStyles(theme => ({
    root: {
        paddingTop: '1.8rem',
        marginBottom: '8.6rem',
        overflow: 'auto',
        alignItems: 'center',
    },
    text: {
        marginTop: '1.8rem'
    }
}))

function EmptyStateColleagueBookings({width, height}) {
    const theme = useTheme()
    const classes = useStyle(theme);
    const {t} = useTranslation()

    return (
        <div className={classes.root} style={{width: width, height: height}}>
            <ListEmptyStateSvg/>
            <div className={classes.text}>
                <Typography variant={'h5'}>
                    {t('no_bookings_yet')}
                </Typography>
                <Typography variant={'body1'}>
                    {t('no_bookings_start')}
                </Typography>
            </div>
        </div>
    )
}

export default EmptyStateColleagueBookings