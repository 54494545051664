import {isEmpty} from "lodash";

const initialState = {
    loginUrls: {},
    adminLoginUrls: {},
    authenticatedMap: {},
    adminAuthenticatedMap: {},
    getLoginUrlPending: {},
    logoutPending: false
}

function thirdPartyAuthenticationReducer(state = initialState, action) {

    switch (action.type) {
        case 'GET_THIRD_PARTY_LOGIN_URLS_FULFILLED': {
            let loginUrls = Object.assign({}, action.payload.data)
            return {
                ...state,
                loginUrls: loginUrls,
            }
        }
        case 'GET_THIRD_PARTY_ADMIN_LOGIN_URLS_FULFILLED': {
            let loginUrls = Object.assign({}, action.payload.data)
            return {
                ...state,
                adminLoginUrls: loginUrls
            }
        }
        case 'GET_USER_AUTHENTICATED_MAP_FULFILLED': {
            let map = Object.assign({}, action.payload.data)
            return {
                ...state,
                authenticatedMap: map
            }
        }
        case 'GET_ADMIN_AUTHENTICATED_MAP_FULFILLED': {
            let map = Object.assign({}, action.payload.data)
            return {
                ...state,
                adminAuthenticatedMap: map
            }
        }
        case 'THIRD_PARTY_USER_LOGOUT_FULFILLED': {
            let map = Object.assign({}, state.authenticatedMap)
            map[action.meta] = false

            if (action.payload.data && !isEmpty(action.payload.data))
                window.location.href = action.payload.data

            return {
                ...state,
                authenticatedMap: map
            }
        }
        case 'THIRD_PARTY_ADMIN_LOGOUT_PENDING': {
            return {
                ...state,
                logoutPending: true
            }
        }
        case 'THIRD_PARTY_ADMIN_LOGOUT_FULFILLED': {
            let map = Object.assign({}, state.adminAuthenticatedMap)
            map[action.meta] = null
            return {
                ...state,
                adminAuthenticatedMap: map,
                logoutPending: false
            }
        }
        case 'THIRD_PARTY_ADMIN_LOGOUT_REJECTED': {
            return {
                ...state,
                logoutPending: false
            }
        }

        default:
            return state

    }
}

export default thirdPartyAuthenticationReducer
