import {isEmpty, isEqual} from "lodash";
import {CLOCKED_IN, CLOCKED_OUT, ON_BREAK} from "../common/utils/NameUtils.js";
import {isSameDay, isToday} from "../common/utils/TimeUtils.js";

const initialState = {
    user: {},
    userPending: false,
    users: [],
    overallUsersCount: 0,
    roles: null,
    person: {},
    personPending: false,
    shareStatus: null,
    availabilityStatus: null,
    userLoaded: false,
    createUserPending: false,
    paginationToken: null,
    passwordIncorrect: false,
    usersLoading: false,
    profilePicture: null,
    profilePictureLoading: false,
    getProfilePicturePending: false,
    userAssignments: [],
    getAssignmentsPending: false,
    homeZone: null,
    assignMeetingPending: false,
    notificationGroups: [],
    coworkersPending: false,
    filteredCoworkersWithAssignments: {},
    filteredCoworkersWithAssignmentsToken: 0,
    selectedCoworker: null,
    resetPasswordRequestPending: false,
    forgotPasswordPending: false,
    clockState: null,
    todaysTimeTrackings: [],
    importUsersPending: false
}

function userReducer(state = initialState, action) {

    switch (action.type) {

        case 'GET_USER_PENDING':
            return {
                ...state,
                userPending: true,
                userLoaded: false
            }

        case 'GET_USER_FULFILLED':
            return {
                ...state,
                user: action.payload.data,
                userPending: false,
                userLoaded: true
            }

        case 'GET_USER_REJECTED':
            return {
                ...state,
                userPending: false,
                userLoaded: true,
                user: {}
            }

        case 'GET_USERS_FILTERED_PENDING':
            return {
                ...state,
                usersLoading: true
            }

        case 'GET_USERS_FILTERED_FULFILLED':
            const data = action.payload.data

            let loadedUsers = data.first || isEmpty(state.users) ? [...data.content] : [...state.users, ...data.content]

            return {
                ...state,
                users: loadedUsers,
                paginationToken: data.last ? null : data.number,
                usersLoading: false,
            }

        case 'GET_USERS_PENDING':
            return {
                ...state,
                usersLoading: true
            }

        case 'GET_USERS_REJECTED':
            return {
                ...state,
                usersLoading: false
            }

        case 'GET_USERS_FULFILLED':
            return {
                ...state,
                users: action.payload.data,
                usersLoading: false
            }

        case 'GET_AWS_USERS_PENDING':
            return {
                ...state,
                usersLoading: true
            }

        case 'GET_AWS_USERS_REJECTED':
            return {
                ...state,
                usersLoading: false,
            }

        case 'GET_AWS_USERS_FULFILLED':
            let tmpUsers = state.users;
            let tmpAwsList = action.payload.data[1]
            if (action.payload.config.params.paginationToken && !action.meta.replaceAlreadyLaoded)
                tmpUsers.push(...tmpAwsList)
            else
                tmpUsers = action.payload.data[1]

            return {
                ...state,
                users: tmpUsers,
                paginationToken: action.payload.data[0],
                usersLoading: false
            }

        case 'CLEAR_USERS':
            return {
                ...state,
                users: [],
            }

        case 'CREATE_USER_PENDING':
            return {
                ...state,
                createUserPending: true,
            }

        case 'CREATE_USER_FULFILLED':
            return {
                ...state,
                users: [...state.users, action.payload.data],
                createUserPending: false,
            }

        case 'CREATE_USER_REJECTED': {
            return {
                ...state,
                createUserPending: false,
            }
        }

        case 'UPDATE_USER_FULFILLED': {
            let users = state.users
            let userIndex = users.findIndex(person => person.id === action.payload.data.id)
            users[userIndex] = action.payload.data

            let newProfilePicture = action.payload.data.profilePictureId ? state.profilePicture : null

            return {
                ...state,
                users: [...users],
                person: action.payload.data,
                profilePicture: newProfilePicture
            }
        }

        case 'ADMIN_UPDATE_USER_FULFILLED': {
            let users = state.users
            let userIndex = users.findIndex(person => person.id === action.payload.data.id)
            users[userIndex] = action.payload.data

            return {
                ...state,
                users: [...users],
            }
        }

        case 'CHANGE_PASSWORD_FULFILLED': {
            return {
                ...state,
                passwordIncorrect: false
            }
        }

        case 'ADMIN_SET_PASSWORD_FULFILLED': {
            return {
                ...state,
                user: {}
            }
        }

        case 'CHANGE_PASSWORD_REJECTED': {
            return {
                ...state,
                passwordIncorrect: true
            }
        }

        case 'RESET_PASSWORD_INCORRECT': {
            return {
                ...state,
                passwordIncorrect: false
            }
        }

        case 'RESET_PASSWORD_PENDING': {
            return {
                ...state,
                resetPasswordRequestPending: true
            }
        }
        case 'RESET_PASSWORD_FULFILLED':
        case 'RESET_PASSWORD_REJECTED':
            return {
                ...state,
                resetPasswordRequestPending: false
            }

        case 'FORGOT_PASSWORD_PENDING': {
            return {
                ...state,
                forgotPasswordPending: true
            }
        }
        case 'FORGOT_PASSWORD_FULFILLED':
        case 'FORGOT_PASSWORD_REJECTED':
            return {
                ...state,
                forgotPasswordPending: false
            }

        case 'DELETE_USER_FULFILLED': {
            return {
                ...state,
                users: [...state.users.filter(user => user.id !== action.payload.data)]
            }
        }

        case 'GET_PERSON_PENDING':
            return {
                ...state,
                personPending: true
            }

        case 'GET_PERSON_FULFILLED':
            return {
                ...state,
                person: action.payload.data,
                todaysTimeTrackings: action.payload.data && action.payload.data.todaysTimeTrackings ? action.payload.data.todaysTimeTrackings : [],
                personPending: false
            }

        case 'GET_PERSON_REJECTED':
            return {
                ...state,
                personPending: false
            }

        case 'GET_USER_ROLES_FULFILLED':
            return {
                ...state,
                roles: action.payload.data
            }

        case 'SEARCH_USERS_FULFILLED':
            return {
                ...state,
                users: action.payload.data[1],
                paginationToken: action.payload.data[0],
                usersLoading: false
            }

        case 'SEARCH_USERS_PENDING':
            return {
                ...state,
                usersLoading: true
            }

        case 'SEARCH_USERS_REJECTED':
            return {
                ...state,
                usersLoading: false
            }

        case 'GET_USER_COUNT_FULFILLED':
            return {
                ...state,
                overallUsersCount: action.payload.data
            }

        case 'GET_PROFILE_PICTURE_OF_USER_FULFILLED':
            const image = action.payload.data[Object.keys(action.payload.data)[0]]
            return {
                ...state,
                profilePicture: 'data:image/png;base64,' + image,
                getProfilePicturePending: false
            }

        case 'GET_PROFILE_PICTURE_OF_USER_PENDING':
            return {
                ...state,
                getProfilePicturePending: true
            }

        case 'GET_PROFILE_PICTURE_OF_USER_REJECTED':
            return {
                ...state,
                getProfilePicturePending: false
            }

        case 'GET_PROFILE_PICTURE_OF_COWORKER_FULFILLED': {
            if (state.profilePicture === null) {
                if (state.person.id && Object.keys(action.payload.data)[0] === state.person.id.toString()) {
                    return {
                        ...state,
                        profilePicture: action.payload.data[Object.keys(action.payload.data)[0]]
                    }
                }
            }
            return {...state}
        }

        case 'SET_UPLOAD_PROFILE_PICTURE_PENDING':
        case 'UPLOAD_PROFILE_PICTURE_PENDING':
            return {
                ...state,
                profilePictureLoading: true,
            }

        case 'UPLOAD_PROFILE_PICTURE_FULFILLED':
            return {
                ...state,
                profilePicture: action.payload.data,
                profilePictureLoading: false,
            }

        case 'SET_HOMEZONE_FULFILLED':
        case 'GET_HOMEZONE_FULFILLED':
            return {
                ...state,
                homeZone: action.payload.data
            }

        case 'DELETE_PROFILE_PICTURE_FULFILLED':
            let person = state.person
            person.profilePictureId = null
            return {
                ...state,
                profilePicture: null,
                person: person
            }

        case 'FETCH_SHARE_STATUS_FULFILLED':
            return {
                ...state,
                shareStatus: action.payload.data
            }

        case 'POST_SHARE_STATUS_FULFILLED':
            return {
                ...state,
                shareStatus: action.payload.data
            }

        case 'GET_ASSIGNMENTS_OF_USER_FULFILLED':
            return {
                ...state,
                userAssignments: action.payload.data,
                getAssignmentsPending: false
            }

        case 'UPDATE_WORKPLACE_ASSIGNMENT_FULFILLED':
        case 'UPDATE_RESOURCE_ASSIGNMENT_FULFILLED':
        case 'UPDATE_MEETING_FULFILLED':
            return {
                ...state,
                userAssignments: [...state.userAssignments.filter(assignment => !isEqual(assignment.id, action.payload.data.data.id)), action.payload.data.data]
            }

        case 'GET_ASSIGNMENTS_OF_USER_PENDING':
            return {
                ...state,
                getAssignmentsPending: true
            }

        case 'GET_ASSIGNMENTS_OF_USER_REJECTED':
            return {
                ...state,
                getAssignmentsPending: false
            }

        case 'ASSIGN_MEETING_TO_PERSON_PENDING':
        case 'JOIN_MEETING_PENDING':
            return {
                ...state,
                assignMeetingPending: true
            }

        case 'JOIN_MEETING_REJECTED':
        case 'ASSIGN_MEETING_TO_PERSON_REJECTED':
            return {
                ...state,
                assignMeetingPending: false
            }

        case 'ASSIGN_WORKPLACE_TO_PERSON_FULFILLED': {
            let updatedAssignments = [...state.userAssignments]

            if (action.payload.config.params.deleteExistingAssignmentsAtDate) {
                updatedAssignments = updatedAssignments.filter(assignment => !isSameDay(assignment.timePeriod.startDate, action.payload.data.timePeriod.startDate))
            }

            updatedAssignments.push(action.payload.data)

            return {
                ...state,
                userAssignments: updatedAssignments,
            }
        }

        case 'JOIN_MEETING_FULFILLED':
        case 'ASSIGN_MEETING_TO_PERSON_FULFILLED': {
            let userAssignments = [...state.userAssignments]
            userAssignments.push(action.payload.data.data)

            return {
                ...state,
                userAssignments: userAssignments,
                assignMeetingPending: false
            }
        }
        case 'ASSIGN_RESOURCE_TO_USER_FULFILLED': {
            return {
                ...state,
                userAssignments: [...state.userAssignments, action.payload.data],
            }
        }

        case 'DELETE_WORKPLACE_ASSIGNMENTS_AT_DATE_FULFILLED':
        case 'DELETE_RESOURCE_ASSIGNMENT_FULFILLED':
        case 'DELETE_WORKPLACE_ASSIGNMENT_FULFILLED': {
            let deletedAssignments = Array.isArray(action.payload.data) ? action.payload.data : [action.payload.data]
            deletedAssignments = deletedAssignments.map(a => a.id)
            let newAssignments = state.userAssignments
            newAssignments = newAssignments.filter(assignment =>
                !deletedAssignments.includes(assignment.id))
            return {
                ...state,
                userAssignments: [...newAssignments]
            }
        }
        case 'DELETE_MEETING_ASSIGNMENT_FULFILLED': {
            let newAssignments = state.userAssignments
            newAssignments = newAssignments.filter(assignment =>
                !(assignment.timePeriod.startDate === action.payload.data.data.timePeriod.startDate
                    && assignment.timePeriod.endDate === action.payload.data.data.timePeriod.endDate
                    && assignment.person.id === action.payload.data.data.person.id
                    && assignment.space.id === action.payload.data.data.space.id))
            return {
                ...state,
                userAssignments: [...newAssignments]
            }
        }

        case 'SET_ATTENDANCE_STATUS_FULFILLED':
            return {
                ...state,
                userAssignments: [...state.userAssignments.filter(assignment => !isEqual(assignment.id, action.payload.data.data.id)), action.payload.data.data]
            }

        case 'SET_NOTIFICATION_SUBSCRIPTION_FULFILLED':
        case 'SET_NOTIFICATION_GROUPS_FULFILLED':
        case 'GET_NOTIFICATION_GROUPS_FULFILLED':
            return {
                ...state,
                notificationGroups: action.payload.data
            }

        case 'GET_COWORKERS_WITH_ASSIGNMENTS_AT_DATE_PENDING':
        case 'GET_COWORKERS_WITH_ASSIGNMENTS_AT_DATE_FILTERED_PENDING':
            return {
                ...state,
                coworkersPending: true
            }

        case 'GET_COWORKERS_WITH_ASSIGNMENTS_AT_DATE_FILTERED_FULFILLED':
            if (action.meta.resetPaginationToken === true || (action.payload.data.paginationToken !== null && action.payload.data.paginationToken <= 1)) {
                return {
                    ...state,
                    filteredCoworkersWithAssignments: action.payload.data.personAssignmentsMap,
                    filteredCoworkersWithAssignmentsToken: action.payload.data.paginationToken === null ? null : parseInt(action.payload.data.paginationToken),
                    coworkersPending: false
                }
            }

            return {
                ...state,
                filteredCoworkersWithAssignments: Object.assign(state.filteredCoworkersWithAssignments, action.payload.data.personAssignmentsMap),
                filteredCoworkersWithAssignmentsToken: action.payload.data.paginationToken === null ? null : parseInt(action.payload.data.paginationToken),
                coworkersPending: false
            }

        case 'SELECT_COWORKER':
            return {
                ...state,
                selectedCoworker: action.payload
            }

        case 'GET_GAME_STATUS_FULFILLED': {
            let updatedPerson = state.person
            updatedPerson.gameStatus = action.payload.data
            return {
                ...state,
                person: updatedPerson
            }
        }

        case 'SET_WORKING_HOURS_FULFILLED': {
            return {
                ...state,
                person: action.payload.data
            }
        }


        case 'FETCH_AVAILABILITY_STATUS_FULFILLED':
            return {
                ...state,
                availabilityStatus: action.payload.data
            }

        case 'SET_AVAILABILITY_STATUS_FULFILLED':
            return {
                ...state,
                availabilityStatus: action.payload.data
            }

        case 'GET_CLOCK_STATE_FULFILLED':
            return {
                ...state,
                clockState: action.payload.data
            }

        case 'GET_TODAYS_TIME_TRACKINGS_FULFILLED':
            let dataMap = action.payload.data
            if (!dataMap || Object.keys(dataMap).length !== 1)
                return state
            const [todaysTrackings] = Object.values(dataMap)

            return {
                ...state,
                todaysTimeTrackings: todaysTrackings
            }

        case 'CLOCK_USER_IN_PENDING':
            return {
                ...state,
                clockInPending: true
            }
        case 'CLOCK_USER_IN_REJECTED':
            return {
                ...state,
                clockInPending: false
            }
        case 'CLOCK_USER_IN_FULFILLED':
        case 'STOP_CLOCK_BREAK_FULFILLED':
            return {
                ...state,
                clockState: CLOCKED_IN,
                todaysTimeTrackings: action.payload.data,
                clockInPending: false
            }

        case 'ADD_TIMETRACKING_FULFILLED':
        case 'UPDATE_TIMETRACKING_FULFILLED':
        case 'ADD_TIMETRACKING_BREAK_FULFILLED':
        case 'DELETE_TIMETRACKING_BREAK_FULFILLED':
        case 'UPDATE_TIMETRACKING_BREAK_FULFILLED': {
            if (action.payload.data[0] && isToday(action.payload.data[0].clockInTime)) {
                return {
                    ...state,
                    todaysTimeTrackings: action.payload.data
                }
            }
            return state
        }
        case 'DELETE_TIMETRACKING_FULFILLED': {
            if (isToday(action.payload.data.clockInTime)) {
                return {
                    ...state,
                    todaysTimeTrackings: [...state.todaysTimeTrackings.filter(entry => entry.id !== action.payload.data.id)]
                }
            }
            return state
        }

        case 'CLOCK_USER_OUT_FULFILLED':
            return {
                ...state,
                clockState: CLOCKED_OUT,
                todaysTimeTrackings: action.payload.data
            }

        case 'START_CLOCK_BREAK_FULFILLED':
            return {
                ...state,
                clockState: ON_BREAK,
                todaysTimeTrackings: action.payload.data
            }

        case 'IMPORT_USERS_PENDING':
            return {
                ...state,
                importUsersPending: true
            }
        case 'IMPORT_USERS_FULFILLED':
        case 'IMPORT_USERS_REJECTED':
            return {
                ...state,
                importUsersPending: false
            }

        default:
            return state
    }
}

export default userReducer