import * as React from "react"

function IntegrationsImageSvgIcon(props) {
    return (
        <svg width={287} height={225} viewBox={'0 0 287 225'} xmlns="http://www.w3.org/2000/svg" {...props}>
            <title>{"Graphics/Integrations"}</title>
            <defs>
                <linearGradient
                    x1="22.792%"
                    y1="55.205%"
                    x2="39.396%"
                    y2="73.824%"
                    id="integration_svg_b"
                >
                    <stop stopColor="#FF7942" offset="0%"/>
                    <stop stopColor="#FFB082" offset="100%"/>
                </linearGradient>
                <linearGradient
                    x1="33.843%"
                    y1="13.447%"
                    x2="68.627%"
                    y2="74.326%"
                    id="integration_svg_c"
                >
                    <stop stopColor="#333" offset="0%"/>
                    <stop offset="100%"/>
                </linearGradient>
                <linearGradient x1="24.785%" y1="58.018%" x2="100%" y2="58.018%" id="integration_svg_d">
                    <stop stopColor="#ADE1E7" offset="0%"/>
                    <stop stopColor="#6AD2DE" offset="100%"/>
                </linearGradient>
                <linearGradient
                    x1="15.639%"
                    y1="17.212%"
                    x2="89.615%"
                    y2="71.821%"
                    id="integration_svg_e"
                >
                    <stop stopColor="#333" offset="0%"/>
                    <stop offset="100%"/>
                </linearGradient>
                <linearGradient x1="30.395%" y1="100%" x2="62.513%" y2="0%" id="integration_svg_f">
                    <stop stopColor="#ADE1E7" offset="0%"/>
                    <stop stopColor="#6AD2DE" offset="100%"/>
                </linearGradient>
                <linearGradient x1="6.026%" y1="40.274%" x2="91.617%" y2="66.03%" id="integration_svg_g">
                    <stop stopColor="#FF7942" offset="0%"/>
                    <stop stopColor="#FFB082" offset="100%"/>
                </linearGradient>
                <filter
                    x="-16.1%"
                    y="-20%"
                    width="132.1%"
                    height="140%"
                    filterUnits="objectBoundingBox"
                    id="integration_svg_a"
                >
                    <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
                    <feGaussianBlur
                        stdDeviation={12}
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                    />
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g
                    filter="url(#integration_svg_a)"
                    transform="translate(.402 -10.847)"
                    fillRule="nonzero"
                >
                    <path
                        d="m23.632 101.801 8.41 17.12 16.123 28.631c24.79 44.017 78.516 62.633 125.225 43.39 36.112-14.877 59.737-30.77 70.876-47.68 9.805-14.886 14.36-33.637 13.665-56.253-.95-30.913-26.781-55.203-57.695-54.253a56.033 56.033 0 0 0-12.771 1.881l-66.447 17.8a100 100 0 0 1-28.166 3.38l-39.264-.9c-17.969-.411-32.87 13.822-33.28 31.79a32.544 32.544 0 0 0 3.324 15.094Z"
                        fill="url(#integration_svg_b)"
                        transform="translate(14.273)"
                    />
                    <path
                        d="M139.373 114.498a317.936 317.936 0 0 0-2.69 16.877 359.844 359.844 0 0 0-2.694 29.914c-1.142 23.502 16.986 43.48 40.488 44.62a42.605 42.605 0 0 0 20.638-4.21l33.083-16.024a44.936 44.936 0 0 0 25.348-40.442 52.462 52.462 0 0 0-23.475-43.727l-23.34-15.472c-20.196-13.388-47.42-7.869-60.808 12.327a43.871 43.871 0 0 0-6.55 16.137Z"
                        fill="url(#integration_svg_c)"
                        transform="rotate(-180 200.683 142.5)"
                    />
                    <path
                        d="M46.964 81.734 58.24 111.5l9.57 22.031c21.15 48.693 77.768 71.021 126.461 49.871a96.125 96.125 0 0 0 47.403-44.626c9.34-18.382 13.168-42.023 11.486-70.923-1.711-29.402-26.933-51.85-56.335-50.138a53.327 53.327 0 0 0-14.397 2.861l-41.863 14.54a100 100 0 0 1-35.776 5.49l-27.925-.83c-17.109-.507-31.39 12.95-31.898 30.058-.12 4.06.558 8.103 1.997 11.9Z"
                        fill="url(#integration_svg_d)"
                        transform="rotate(-180 149.183 111.5)"
                    />
                    <path
                        d="M261.51 198.975a13.299 13.299 0 0 1 9.71-10.548l.784-.206a8.916 8.916 0 0 1 10.984 6.736l.734 3.39a8.998 8.998 0 0 1-9.974 10.824l-5.28-.699a8.15 8.15 0 0 1-6.957-9.497Z"
                        fill="url(#integration_svg_e)"
                    />
                    <path
                        d="m23.667 23.325.137-1.055a8.76 8.76 0 0 1 5.739-7.119 4.45 4.45 0 0 1 5.891 3.49l1.004 6.291a5.333 5.333 0 0 1-6.209 6.089l-1.068-.192a6.741 6.741 0 0 1-5.494-7.504Z"
                        fill="url(#integration_svg_f)"
                    />
                    <path
                        d="M3.064 44.52a18.584 18.584 0 0 1 14.174-14.322l3.317-.734c8.38-1.853 16.722 3.267 18.863 11.578l.67 2.6c1.719 6.668-2.294 13.468-8.963 15.186a12.47 12.47 0 0 1-4.489.318l-14.121-1.568c-5.975-.664-10.28-6.045-9.617-12.02.039-.348.094-.695.166-1.038Z"
                        fill="url(#integration_svg_g)"
                        transform="rotate(75 22.598 43.641)"
                    />
                </g>
                <g strokeLinejoin="round" stroke="#FFF" strokeWidth={4.5}>
                    <path
                        d="m126.928 56.03 3.912 14.555a6.33 6.33 0 0 0-4.752-.6c-3.38.903-5.385 4.367-4.48 7.736.906 3.37 4.38 5.37 7.759 4.466a6.312 6.312 0 0 0 3.814-2.889l3.911 14.557-14.071 3.76a6.287 6.287 0 0 1 2.206 3.333c.906 3.37-1.1 6.833-4.479 7.737-3.379.902-6.852-1.098-7.758-4.467a6.271 6.271 0 0 1 .24-3.987l-14.072 3.76-8.197-30.505c-1.087-4.044 1.32-8.2 5.375-9.283l30.592-8.174ZM191 115.84H176.43a6.276 6.276 0 0 0 1.267-3.79c0-3.489-2.836-6.316-6.335-6.316-3.498 0-6.334 2.827-6.334 6.316 0 1.422.471 2.734 1.267 3.79h-14.569v-14.527a6.318 6.318 0 0 1-3.8 1.262c-3.499 0-6.335-2.827-6.335-6.316 0-3.488 2.836-6.316 6.334-6.316 1.426 0 2.742.47 3.8 1.263l.001-14.527H183.4c4.198 0 7.6 3.394 7.6 7.58v31.58Z"/>
                    <path
                        d="M151.727 155v-14.527a6.318 6.318 0 0 0 3.8 1.263c3.5 0 6.335-2.828 6.335-6.316 0-3.489-2.836-6.316-6.334-6.316-1.426 0-2.742.47-3.801 1.263V115.84h14.569a6.276 6.276 0 0 1-1.267-3.79c0-3.488 2.836-6.316 6.335-6.316 3.498 0 6.334 2.828 6.334 6.316a6.276 6.276 0 0 1-1.266 3.79H191v31.58c0 4.186-3.403 7.58-7.601 7.58h-31.672Z"/>
                    <path
                        d="M112.454 115.84h15.114a6.286 6.286 0 0 0-1.812 4.421c0 3.488 2.836 6.316 6.335 6.316 3.498 0 6.334-2.828 6.334-6.316 0-1.722-.69-3.282-1.811-4.422h15.113v14.527a6.318 6.318 0 0 1 3.8-1.262c3.499 0 6.335 2.828 6.335 6.316s-2.836 6.316-6.334 6.316a6.318 6.318 0 0 1-3.8-1.263V155h-31.672c-4.198 0-7.602-3.393-7.602-7.58v-31.58Z"/>
                </g>
            </g>
        </svg>
    )
}

export default IntegrationsImageSvgIcon
