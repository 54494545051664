import React from 'react';
import clsx from "clsx";
import {Card, makeStyles} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";
import PropTypes from "prop-types";

const useStyle = props => makeStyles(theme => ({
    root: {
        cursor: 'pointer',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.15)',
        backgroundColor: theme.colors.palette.neutral.greyNeutral,
        border: '1px solid',
        borderRadius: '12px !important',
        borderColor: theme.colors.palette.neutral.greyMedium,
        userSelect: 'none',
        boxSizing: 'border-box',
        height: '100%'
    },
    selected: {
        margin: '-1px',
        border: 'solid 2px !important',
        borderColor: theme.colors.palette.corporate.skinMain + '!important',
        boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.3)',
        height: "auto"
    },
    disabled: {
        cursor: 'default',
        backgroundColor: `${theme.colors.palette.neutral.greyDisabled} !important`,
        boxShadow: 'none',
    }
}))

function CardWrapper({
                         children,
                         isSelected,
                         isDisabled,
                         onClick,
                         fullWidth,
                         halfWidth,
                         className,
                         gridClassName,
                         isFlex,
                         ...props
                     }) {
    const theme = useTheme()
    const classes = useStyle(props)(theme);

    return (
        <div style={{
            flex: isFlex && 1,
            padding: 0, width: fullWidth ? '100%' : halfWidth ? '50%' : '',
            height: 'inherit',
        }}
             className={gridClassName}>
            <Card variant="outlined"
                  className={clsx(classes.root, isSelected ? classes.selected : '', isDisabled ? classes.disabled : '', className)}
                  onClick={e => {
                      if (!isDisabled && onClick)
                          onClick(e)
                  }}>
                {React.cloneElement(children)}
            </Card>
        </div>
    );
}

CardWrapper.propTypes = {
    onClick: PropTypes.func,
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    halfWidth: PropTypes.bool,
};

export default CardWrapper;