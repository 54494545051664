import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

import {makeStyles, Typography} from "@material-ui/core";
import SelectedPersonsComponent from "../WorkingGroup/SelectedPersonsComponent.js";
import PersonAndWorkingGroupSelectionModal from "../../common/elements/PersonAndWorkingGroupSelectionModal.js";
import useTheme from "@material-ui/core/styles/useTheme.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        textAlign: "start",
        flexDirection: 'column',
        gap: theme.innerSmallGap
    },
}))

function SelectPersonsWithModal({
                                    initialSelectedPersons,
                                    initialSelectedWorkingGroups,
                                    initialSelectedExternalPersons,
                                    title,
                                    hideAvailabilityStatus,
                                    withWorkingGroups,
                                    allowExternalPersons,
                                    onConfirmCallback,
                                    loggedInUserAlwaysSelected,
                                    hideDeleteIcon,
                                    modalSubmitText,
                                    modifyWorkingGroups,
                                }) {

    const theme = useTheme();
    const classes = useStyle(theme);
    const {t} = useTranslation();

    const [addPeoplePopupOpen, setAddPeoplePopupOpen] = useState(false)
    const [persons, setPersons] = useState(initialSelectedPersons);
    const [workingGroups, setWorkingGroups] = useState(initialSelectedWorkingGroups);
    const [externalPersons, setExternalPersons] = useState(initialSelectedExternalPersons);

    useEffect(() => {
        setPersons(initialSelectedPersons)
    }, [initialSelectedPersons])

    useEffect(() => {
        setWorkingGroups(initialSelectedWorkingGroups)
    }, [initialSelectedWorkingGroups])

    useEffect(() => {
        setExternalPersons(initialSelectedExternalPersons)
    }, [initialSelectedExternalPersons])

    function onRemovePerson(person, modifyGroups = true) {
        if (person.id) {
            const newPersons = persons.filter(p => p.id !== person.id)
            setPersons(newPersons)
            onConfirmCallback(newPersons, null, null)

            if (modifyGroups)
                removePersonFromGroups(person)
        } else {
            const newExternals = externalPersons.filter(p => p.email !== person.email)
            setExternalPersons(newExternals)
            onConfirmCallback(null, null, newExternals)
        }
    }

    function removePersonFromGroups(person) {
        let newGroups = []
        for (let group of workingGroups) {
            let newGroup = {...group}
            newGroup.members = group.members.filter(p => p.id !== person.id)
            newGroups.push(newGroup)
        }
        setWorkingGroups(newGroups)
        onConfirmCallback(null, newGroups, null)
    }

    function onRemoveGroup(group) {
        const groups = workingGroups.filter(g => g.id !== group.id)
        if (groups.length !== withWorkingGroups.length) {
            setWorkingGroups(groups)
            onConfirmCallback(null, groups, null)
        }
    }

    return (
        <div className={classes.root}>
            <Typography variant={"h6"}>{title ?? t('participants')}</Typography>
            <SelectedPersonsComponent setPopupOpenCallback={() => setAddPeoplePopupOpen(true)}
                                      hideDeleteIcon={hideDeleteIcon}
                                      modifyWorkingGroups={modifyWorkingGroups}
                                      selectedItems={[...persons, ...workingGroups, ...externalPersons]}
                                      onDeletePersonCallback={(person, modifyGroups) => onRemovePerson(person, modifyGroups)}
                                      onDeleteGroupCallback={(group) => onRemoveGroup(group)}
                                      loggedInUserAlwaysSelected={loggedInUserAlwaysSelected}/>

            <PersonAndWorkingGroupSelectionModal open={addPeoplePopupOpen}
                                                 onConfirm={(selectedPersons, selectedGroups, selectedExternals) => {
                                                     setPersons(selectedPersons ?? [])
                                                     setExternalPersons(selectedExternals ?? [])
                                                     setWorkingGroups(selectedGroups ?? [])
                                                     onConfirmCallback(selectedPersons, selectedGroups, selectedExternals)
                                                 }}
                                                 submitText={modalSubmitText}
                                                 onClose={() => setAddPeoplePopupOpen(false)}
                                                 initialSelectedPersons={persons}
                                                 initialSelectedExternalPersons={externalPersons}
                                                 initialSelectedGroups={workingGroups}
                                                 preventHistoryGoBack
                                                 loggedInUserAlwaysSelected={loggedInUserAlwaysSelected}
                                                 hideAvailabilityStatus={hideAvailabilityStatus}
                                                 withWorkingGroups={withWorkingGroups}
                                                 allowExternalPersons={allowExternalPersons}/>

        </div>
    )
}

SelectPersonsWithModal.propTypes = {
    initialSelectedPersons: PropTypes.array,
    initialSelectedExternalPersons: PropTypes.array,
    initialSelectedWorkingGroups: PropTypes.array,

    withWorkingGroups: PropTypes.bool,
    modifyWorkingGroups: PropTypes.bool,
    allowExternalPersons: PropTypes.bool,
    hideAvailabilityStatus: PropTypes.bool,
    loggedInUserAlwaysSelected: PropTypes.bool,
    hideDeleteIcon: PropTypes.bool,

    title: PropTypes.string,
    modalSubmitText: PropTypes.string,

    onConfirmCallback: PropTypes.func.isRequired
}

SelectPersonsWithModal.defaultProps = {
    initialSelectedPersons: [],
    initialSelectedExternalPersons: [],
    initialSelectedWorkingGroups: [],
    loggedInUserAlwaysSelected: true,
    hideDeleteIcon: false,
    modifyWorkingGroups: true,
}

export default SelectPersonsWithModal