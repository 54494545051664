import createTheme from "@material-ui/core/styles/createTheme";

const font = "'IBM Plex Sans', sans-serif";

export const colors = {
    palette: {
        corporate: {
            greenLightest: '#3A8F94',
            greenLight: '#1C5558',
            greenMain: '#0C4043',
            greenDark: '#0a373a',

            blueMain: '#58BCCC',
            blueDark: '#1496ac',
            blueDarkest: '#246570',

            redLight: '#ffb9b9',
            redMain: '#F74E44',
            redDark: '#923636',

            skinLight: '#FFC6B3',
            skinMain: '#FF8C65',
            skinDark: '#CC7052',
        },

        neutral: {
            darkMain: '#333333',
            darkNeutral: '#4c4c4c',

            greyDisabled: '#e5e5e5',
            greyMain: '#b2b2b2',
            greyMedium: '#f4f5f5',
            greyNeutral: '#fafafa',

            white: '#ffffff',

            black: '#000',
        },

        indicator: {
            yellow: '#ffdc7b',
            yellowMain: '#ffc75c',

            purpleMain: '#7e7eff',
            plum: '#7D1F6A',

            pink: '#FF5C8F',

            green: '#b1e579',
            greenLight: '#eaef6e',
            greenMain: '#60bf82'
        },

        status: {
            online: '#60BF82',
            absent: '#F74E44',
            meeting: '#7D1F6A',
            offline: '#E5E5E5',
        },
        text: {
            primary: '#333333',
        }
    },
    gradients: {
        skinMain: 'linear-gradient(75deg, #ff7942, #ffb082)',
        blueMain: 'linear-gradient(75deg, #58BCCC, #9bdde5)',
    }
}


const muiTheme = createTheme({
    colors: colors,
    breakpoints: {
        values: {
            xs: 0,
            sm: 375,
            md: 415,
            lg: 768,
            xl: 1024,
            xlll: 1280,
            xll: 1920,
            xxl: 2560,
            xxxl: 3840,
        },
    },
    palette: {
        primary: {
            light: colors.palette.corporate.skinLight,
            main: colors.palette.corporate.skinMain,
            dark: colors.palette.corporate.skinDark,
        },
        secondary: {
            light: colors.palette.corporate.greenLight,
            main: colors.palette.corporate.greenMain,
            dark: colors.palette.corporate.greenDark,
        },
        text: {
            primary: colors.palette.neutral.darkMain,
        }
    },
    typography: {
        fontFamily: font,
        htmlFontSize: 10,
        h1: {
            fontSize: '44px',
            fontWeight: '400',
            color: colors.palette.neutral.darkMain,
            lineHeight: '4.4rem',
        },
        h2: {
            fontSize: '44px',
            fontWeight: '500',
            color: colors.palette.neutral.darkMain,
            lineHeight: '4.4rem',
        },
        h3: {
            fontSize: '22px',
            fontWeight: '500',
            color: colors.palette.neutral.darkMain,
            lineHeight: '3rem'
        },
        h4: {
            fontSize: '13px',
            fontWeight: '500',
            color: colors.palette.neutral.darkMain,
            lineHeight: '1.9rem',
        },
        h5: {
            fontSize: '17px',
            fontWeight: '500',
            color: colors.palette.neutral.darkMain,
            lineHeight: '2.3rem'
        },
        h6: {
            fontSize: '17px',
            fontWeight: '400',
            color: colors.palette.neutral.greyMain,
            lineHeight: '2.3rem',
        },
        body1: {
            fontSize: '17px',
            fontWeight: '400',
            color: colors.palette.neutral.darkMain,
            lineHeight: '2.3rem',
        },
        body2: {
            fontSize: '17px',
            fontWeight: '400',
            color: colors.palette.neutral.white,
            lineHeight: '2.3rem',
        },
        caption: {
            fontSize: '13px',
            fontWeight: '400',
            color: colors.palette.neutral.greyMain,
            lineHeight: '1.9rem',
        },
        overline: {
            fontSize: '13px',
            fontWeight: '400',
            color: colors.palette.neutral.darkMain,
            lineHeight: '1.9rem',
            textTransform: 'none'
        },
        subtitle1: {
            fontSize: '10px',
            fontWeight: '400',
            color: colors.palette.neutral.greyMain,
            lineHeight: '10px',
        }
    },
    overrides: {
        MuiPickersClock: {
            clock: {
                backgroundColor: colors.palette.corporate.greenMain,
                color: colors.palette.neutral.white,
            },
            pin: {
                backgroundColor: colors.palette.neutral.white,
            },
        },
        MuiSelect: {
            root: {
                textAlign: 'left'
            }
        },
        MuiPickersClockNumber: {
            clockNumber: {
                color: colors.palette.neutral.white
            },
            clockNumberSelected: {
                color: colors.palette.neutral.white,
                background: colors.gradients.skinMain,
            }
        },
        MuiPickersClockPointer: {
            pointer: {
                backgroundColor: colors.palette.corporate.skinMain,
            },
            thumb: {
                borderColor: colors.palette.corporate.greenLight,
            }
        },
        MuiPickersTimePickerToolbar: {
            toolbarAmpmLeftPadding: {
                backgroundColor: 'transparent',
                paddingLeft: '24px'
            },
            hourMinuteLabel: {
                color: colors.palette.neutral.darkMain
            }
        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                fontWeight: 'normal',
                fontSize: '30px',
            }
        },
        MuiButton: {
            textPrimary: {
                color: colors.palette.neutral.darkMain,
                textTransform: 'none',
            },
            label: {
                fontSize: '17px',
                fontWeight: '500',
                color: colors.palette.neutral.darkMain,
                lineHeight: '2.3rem'
            }
        },
        MuiPickersToolbar: {
            toolbar: {
                justifyContent: 'start',
                backgroundColor: 'none',
                height: '65px',
            }
        },
        MuiDialogTitle: {
            root: {
                '& .MuiTypography-h6': {
                    fontWeight: 500
                }
            }
        },

        //Textfield Input BackgroundColor
        MuiInputBase: {
            input: {
                fontSize: '17px',
                color: colors.palette.neutral.darkMain,
                '&:-webkit-autofill': {
                    transitionDelay: '9999s',
                    transitionProperty: 'background-color, color',
                    marginBottom: '10px',
                },
                '&:-webkit-autofill::first-line': {
                    fontSize: '17px !important',
                }
            },
        },
        MuiTableRow: {
            root: {
                '&:nth-child(odd)': {
                    backgroundColor: '#F4F5F5',
                },
                '&:nth-child(even)': {
                    backgroundColor: '#fcfcfc',
                }
            },
            head: {
                backgroundColor: 'transparent !important',
            }
        },
        MuiTableCell: {
            root: {
                borderBottom: 'none',
                '&:first-child': {
                    borderRadius: '6px 0 0 6px',
                },
                '&:last-child': {
                    borderRadius: '0 6px 6px 0',
                }
            }
        },
        MuiPopover: {
            paper: {
                borderRadius: '12px',
            }
        }
    }
})

const height = {
    header: {
        minHeight: '7.6rem',
        big: {
            height: '24.5rem'

        }
    },
}

const theme = {
    ...muiTheme,
    goconutLogo: {
        fill: colors.palette.corporate.greenLight,
        justifyContent: 'flex-start',
        [muiTheme.breakpoints.down('md')]: {
            maxWidth: '160px',
            justifyContent: 'center',
            paddingLeft: '6px',
        },
    },
    tabLeft: {
        float: 'left',
        maxWidth: '240px',
    },
    AppbarDropdownButton: {
        textTransform: 'capitalize',
        minWidth: 160,
        borderLeft: '1px solid',
        borderColor: colors.palette.neutral.darkMain,
        fontSize: '1.3rem',
    },
    selectedCard: {
        boxShadow: '0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2)',
        borderColor: colors.palette.neutral.darkMain,
        borderRadius: '18px'
    },
    drawer: {
        width: '24rem',
        color: colors.palette.neutral.greyMedium,
    },
    modalPanel: {
        alignItems: 'center',
        padding: '14px 20px 14px 20px',
        height: '64px',
    },
    modalContentInput: {
        '& .MuiInputBase-input': {
            backgroundColor: colors.palette.neutral.greyNeutral,
            maxWidth: '134px',
            minWidth: '134px',
            maxHeight: '36px',
            borderRadius: '3px',
            padding: '13px',
            fontSize: '14px',
        },
        '& .MuiDivider-root': {
            margin: '0 20px 0 20px',
            backgroundColor: colors.palette.neutral.greyNeutral
        }
    },
    gridSizes: {
        maxWidth: '800px',
    },
    paddingContentContainer: {
        padding: '18px 18px 18px 18px',
    },
    paddingAdminContainerBig: {
        padding: '80px 24px 0px 24px',
    },
    admin: {
        borderRadius: '6px',
        maxWidth: '1024px',
        desktopHeaderHeight: '80px',
        subHeaderHeight: '72px',
    },
    //TODO: clean when old spreadsheet/ popup removed
    popup: {
        borderRadius: 18,
        height: 'calc(100vh - 9rem)', //screen height - small Header (and some more space)
        padding: '1rem 1.8rem 2.8rem 1.8rem'
    },
    staticBottomActionSheet: {
        height: '8.4rem',
    },
    bigOuterGap: '2.1rem',
    outerGap: '1.8rem',
    innerGap: '1.2rem',
    innerSmallGap: '0.9rem',
    content: {
        height: 'calc(100% - ' + height.header.minHeight + ')'
    },
    header: height.header,
}

export default theme
