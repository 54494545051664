import axios from 'axios'

export function getThirdPartyLoginUrls() {
    return {
        type: 'GET_THIRD_PARTY_LOGIN_URLS',
        payload: axios.get(`/api/thirdParty/authentication/authorize`)
    }
}

export function getThirdPartyAdminLoginUrls() {
    return {
        type: 'GET_THIRD_PARTY_ADMIN_LOGIN_URLS',
        payload: axios.get(`/api/thirdParty/authentication/adminAuthorize`)
    }
}

export function getUserAuthenticatedMap() {
    return {
        type: 'GET_USER_AUTHENTICATED_MAP',
        payload: axios.get(`/api/thirdParty/authentication/authenticated`)
    }
}

export function getAdminAuthenticatedMap() {
    return {
        type: 'GET_ADMIN_AUTHENTICATED_MAP',
        payload: axios.get(`/api/thirdParty/authentication/adminAuthenticated`)
    }
}

export function thirdPartyUserLogout(thirdParty) {
    return {
        type: 'THIRD_PARTY_USER_LOGOUT',
        payload: axios.get(`/api/thirdParty/authentication/revoke/${thirdParty}`),
        meta: thirdParty
    }
}

export function thirdPartyAdminLogout(thirdParty, keepCalendarSpaceAssignments) {
    return {
        type: 'THIRD_PARTY_ADMIN_LOGOUT',
        payload: axios.get(`/api/thirdParty/authentication/adminRevoke/${thirdParty}/${keepCalendarSpaceAssignments}`),
        meta: thirdParty
    }
}
