import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";

import {makeStyles, useTheme} from "@material-ui/core";
import {connect} from "react-redux";
import {
    getThirdPartyLoginUrls,
    getUserAuthenticatedMap,
    thirdPartyUserLogout
} from "../../actions/thirdPartyAuthentication-actions.js";
import {navigate, PATH_PROFILE_SETTINGS} from "../../common/utils/NavigationUtils.js";
import {useTranslation} from "react-i18next";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton.js";
import CustomButton from "../CustomButton.js";
import GoogleSvgIcon from "../../common/icons/GoogleSvgIcon.js";
import MicrosoftSvgIcon from "../../common/icons/MicrosoftSvgIcon.js";
import {GOOGLE, MICROSOFT, SLACK} from "../../common/utils/IntegrationsUtils.js";
import SlackSvgIcon from "../../common/icons/SlackSvgIcon.js";
import Typography from "@material-ui/core/Typography";
import IntegrationsImageSvgIcon from "../../common/icons/IntegrationsImageSvgIcon.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: theme.gridSizes.maxWidth,
        padding: theme.paddingContentContainer.padding,
        boxSizing: 'border-box',
    },
    content: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: '2.4rem',
        flex: 1
    },
    info: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '4.2rem'
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        gap: '.8rem'

    }
}));

function ChooseLanguagePage({
                                getThirdPartyLoginUrls,
                                getUserAuthenticatedMap,
                                thirdPartyUserLogout,
                                authenticatedMap,
                                loginUrls,
                                ...props
                            }) {

    const history = useHistory()
    const theme = useTheme()
    const classes = useStyle(theme)
    const {t} = useTranslation()

    useEffect(() => {
        getUserAuthenticatedMap()
        getThirdPartyLoginUrls()
        // eslint-disable-next-line
    }, []);

    return (
        <div className={classes.root}>
            <HeaderWithTitleAndBackButton title={t("integrations")}
                                          backLabel={t("settings")}
                                          onNavigateBack={() => navigate(history, PATH_PROFILE_SETTINGS)}/>
            <div className={classes.content}>
                <div className={classes.info}>
                    <Typography variant={"body1"} align={'left'}>
                        {t("integrations_text")}
                    </Typography>
                    <IntegrationsImageSvgIcon/>
                    <Typography variant={"body1"} align={'left'}>
                        {t("integrations_text_calendar")}
                    </Typography>
                    <Typography variant={"body1"} align={'left'}>
                        {t("integrations_text_status")}
                    </Typography>
                </div>
                <div className={classes.buttons}>
                    <CustomButton icon={<GoogleSvgIcon/>}
                                  textColor={theme.colors.palette.neutral.darkMain}
                                  text={!authenticatedMap[GOOGLE] ? t('google_sign_in') : t('google_sign_out')}
                                  onClick={() => {
                                      authenticatedMap[GOOGLE]
                                          ? thirdPartyUserLogout(GOOGLE)
                                          : window.open(loginUrls[GOOGLE], '_self')
                                  }}/>
                    <CustomButton icon={<MicrosoftSvgIcon/>}
                                  textColor={theme.colors.palette.neutral.darkMain}
                                  text={!authenticatedMap[MICROSOFT] ? t('microsoft_sign_in') : t('microsoft_sign_out')}
                                  onClick={() => {
                                      authenticatedMap[MICROSOFT]
                                          ? thirdPartyUserLogout(MICROSOFT)
                                          : window.open(loginUrls[MICROSOFT], '_self')
                                  }}/>
                    <CustomButton icon={<SlackSvgIcon/>}
                                  textColor={theme.colors.palette.neutral.darkMain}
                                  text={!authenticatedMap[SLACK] ? t('slack_sign_in') : t('slack_sign_out')}
                                  onClick={() => {
                                      authenticatedMap[SLACK]
                                          ? thirdPartyUserLogout(SLACK)
                                          : window.open(loginUrls[SLACK], '_self')
                                  }}/>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loginUrls: state.thirdPartyAuthentication.loginUrls,
        authenticatedMap: state.thirdPartyAuthentication.authenticatedMap,
    }
}

const mapDispatchToProps = {
    getThirdPartyLoginUrls: getThirdPartyLoginUrls,
    getUserAuthenticatedMap: getUserAuthenticatedMap,
    thirdPartyUserLogout: thirdPartyUserLogout,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseLanguagePage)