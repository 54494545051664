import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ActionListItem from "./ActionListItem.js";
import NavigationArrowSvgIcon from "../icons/NavigationArrowSvgIcon.js";


const useStyles = props => makeStyles(theme => ({
    root: {
        '& .MuiAccordionSummary-expandIcon': {
            transform: 'none',
        },
        '& .MuiAccordionSummary-content': {
            margin: 0,
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: props.height ?? '7.1rem',
        },
        '& .MuiPaper-root': {
            backgroundColor: props.backgroundColor ?? theme.colors.palette.neutral.white
        }
    },
    accordion: {
        boxShadow: 'none !important',
    },
    summary: {
        height: props.height ?? '7.1rem',
        padding: 0,
        cursor: 'pointer',
    },
    summaryContent: {
        width: '90%',
    },
    details: {
        padding: 0,
        marginLeft: props.marginChildren + 'px',
    },
    disabled: {
        color: theme.colors.palette.neutral.greyMedium
    },
    adornment: {
        width: '1.8rem',
        minWidth: '1.8rem',
        height: '1.8rem',
    },
    adornmentTransform: {
        width: '1.8rem',
        minWidth: '1.8rem',
        height: '1.8rem',
        transform: 'rotate(-90deg)',
    }
}));

function ActionListAccordion({
                                 text,
                                 unmount,
                                 children,
                                 disabled,
                                 startAdornment,
                                 endAdornment,
                                 initiallyExpanded,
                                 textSecondRow,
                                 textColorSecondRow,
                                 textSecondRowVariant,
                                 hideDivider,
                                 textColor,
                                 ...props
                             }) {
    const classes = useStyles(props)(props.theme);

    const [expanded, setExpanded] = useState(initiallyExpanded);
    const getExpandIcon = () => {
        return expanded
            ? <NavigationArrowSvgIcon className={classes.adornment} style={{transform: 'rotate(90deg)'}}/>
            : <NavigationArrowSvgIcon className={classes.adornmentTransform}
                                      style={{transform: 'rotate(-90deg)'}}/>
    }

    useEffect(() => {
        setExpanded(initiallyExpanded)
    }, [initiallyExpanded])

    return (
        <div className={classes.root}>
            <Accordion className={classes.accordion} TransitionProps={{unmountOnExit: true}} expanded={expanded}>
                <AccordionSummary classes={{root: classes.summary, content: classes.summaryContent}}
                                  onClick={() => setExpanded(!expanded)}>
                    <ActionListItem
                        hideDivider={hideDivider}
                        textSecondRow={textSecondRow}
                        textSecondRowVariant={textSecondRowVariant}
                        textColorSecondRow={textColorSecondRow}
                        startAdornment={startAdornment}
                        textColor={textColor}
                        text={text}
                        centerAdornment={getExpandIcon()}
                        endAdornment={endAdornment}
                    />
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    {children}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

ActionListAccordion.propTypes = {
    text: PropTypes.string,
    unmount: PropTypes.bool,
    children: PropTypes.node,
    startAdornment: PropTypes.element,
    endAdornment: PropTypes.element,
    height: PropTypes.number,
    initiallyExpanded: PropTypes.bool,
    hideDivider: PropTypes.bool,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    textSecondRow: PropTypes.string,
    textColorSecondRow: PropTypes.string,
    textSecondRowVariant: PropTypes.string,
    marginChildren: PropTypes.number
};

ActionListAccordion.defaultProps = {
    initiallyExpanded: false,
    hideDivider: false,
    marginChildren: 66
}

export default ActionListAccordion;