import React from 'react';
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {makeStyles, Typography} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme.js";

import CardWrapper from "../../../common/elements/CardWrapper.js";
import {navigate, PATH_USER_TIMETRACKINGS} from "../../../common/utils/NavigationUtils.js";
import WorkAndBreakTime from "./WorkAndBreakTime.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerSmallGap
    },
    info: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        padding: theme.outerGap,
        boxSizing: "border-box"
    },
    time: {
        minWidth: '23rem'
    }
}))

function TimeTrackingPanel({usersTodaysTimeTrackings}) {
    const {t} = useTranslation()
    const history = useHistory()
    const theme = useTheme()
    const classes = useStyle(theme);

    return (
        <div className={classes.root}>
            <Typography variant={'h6'} align={'left'}>{t('time_entries')}</Typography>
            <CardWrapper fullWidth
                         onClick={() => navigate(history, PATH_USER_TIMETRACKINGS)}>
                <div className={classes.info}>
                    <Typography variant={'body1'} align={'left'}>{t('today')}</Typography>
                    <WorkAndBreakTime timeTrackings={usersTodaysTimeTrackings} className={classes.time}/>
                </div>
            </CardWrapper>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        usersTodaysTimeTrackings: state.user.todaysTimeTrackings,
    }
}

export default connect(mapStateToProps, {})(TimeTrackingPanel)