import * as React from 'react'
import PropTypes from "prop-types";

const QrCodeScanSvgIcon = ({width, height, color, ...props}) => (

    <svg xmlns="http://www.w3.org/2000/svg" width={width || '100%'} height={height || '100%'}
         viewBox={'0 0 24 24'} {...props}>
        <path fill="none" d="M0 0h24v24H0z"/>
        <path fill={color}
              d="M9.5 6.5v3h-3v-3h3M11 5H5v6h6V5zm-1.5 9.5v3h-3v-3h3M11 13H5v6h6v-6zm6.5-6.5v3h-3v-3h3M19 5h-6v6h6V5zm-6 8h1.5v1.5H13V13zm1.5 1.5H16V16h-1.5v-1.5zM16 13h1.5v1.5H16V13zm-3 3h1.5v1.5H13V16zm1.5 1.5H16V19h-1.5v-1.5zM16 16h1.5v1.5H16V16zm1.5-1.5H19V16h-1.5v-1.5zm0 3H19V19h-1.5v-1.5zM22 7h-2V4h-3V2h5v5zm0 15v-5h-2v3h-3v2h5zM2 22h5v-2H4v-3H2v5zM2 2v5h2V4h3V2H2z"/>
    </svg>

)

QrCodeScanSvgIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    color: PropTypes.string,
}

QrCodeScanSvgIcon.defaultProps = {
    color: '#333',
}

export default QrCodeScanSvgIcon