import axios from 'axios';
import {SHARE_STATUS_WORKINGGROUPS} from "../common/utils/NameUtils.js";

export function getUser() {
    return (dispatch) => {
        return dispatch(getUserApiCall())
            .then((res) => {

                if (!res.value)
                    return

                let credentials = res.value.data.email
                if (!credentials) {
                    credentials = res.value.data.userName
                }
                if (credentials)
                    dispatch(getPersonWithCredential(credentials))
            })
            .catch((err) => console.warn('ERROR', err))
    }
}

export function getUserApiCall() {
    return {
        type: 'GET_USER',
        payload: axios.get('/api/user', {withCredentials: true})
            .then(res => {

                if (res.headers['x-custom-redirect']) {
                    window.location = res.headers['x-custom-redirect']
                }

                return res
            })
    }
}

export function getUsers() {
    return {
        type: 'GET_USERS',
        payload: axios.get('/api/person')
    }
}

export function getFilteredUsers(filter, pageToken, loadDeleted = false) {
    return {
        type: 'GET_USERS_FILTERED',
        payload: axios.get('/api/person/filtered', {
            params: {
                filter: filter,
                pageToken: pageToken,
                loadDeleted: loadDeleted
            }
        })
    }
}

export function clearUsers() {
    return {
        type: 'CLEAR_USERS',
    }
}

export function getAwsUsers(paginationToken, replaceAlreadyLaoded) {
    return {
        type: 'GET_AWS_USERS',
        payload: axios.get('/api/users', {
            params: {
                paginationToken: paginationToken
            }
        }),
        meta: {
            replaceAlreadyLaoded: replaceAlreadyLaoded
        }

    }
}

export function getPeopleCount() {
    return {
        type: 'GET_USER_COUNT',
        payload: axios.get('/api/person/count')
    }
}

export function createUser(email, name, surname, group, password) {
    return (dispatch) => {
        return dispatch(createUserApiCall(email, name, surname, group, password))
            .then(() => {
                dispatch(getPeopleCount())
            })
    }
}

function createUserApiCall(email, name, surname, group, password) {
    return {
        type: 'CREATE_USER',
        payload: axios.post(`/api/admin_signup`, null, {
            params: {
                username: email,
                name: name,
                surname: surname,
                group: group,
                password: password
            }
        })
    }
}

export function searchUsers(searchString = '') {
    return {
        type: 'SEARCH_USERS',
        payload: axios.get(`/api/person/admin_search_users`, {
            params: {
                searchString: searchString
            }
        })
    }
}

export function adminUpdateUser(userId, userDto) {
    let awsUsername = userDto.userName ? userDto.userName : ""
    let groupName

    if (userDto.groups) {
        //check if assigned user groups is an array
        userDto.groups = Array.isArray(userDto.groups) ? userDto.groups : [userDto.groups]

        if (userDto.groups.includes('ROLE_ADMIN'))
            groupName = 'ROLE_ADMIN'
        else
            groupName = userDto.groups[0]

    } else {
        groupName = ""
    }

    return {
        type: 'ADMIN_UPDATE_USER',
        payload: axios.patch(`/api/person/admin/${userId}`, userDto, {
            params: {
                group: groupName,
                awsUsername: awsUsername
            }
        })
    }
}

export function updateUser(userId, userDto) {
    return {
        type: 'UPDATE_USER',
        payload: axios.patch(`/api/person/${userId}`, userDto)
    }
}

export function changePassword(userId, currentPassword, newPassword) {
    return {
        type: 'CHANGE_PASSWORD',
        payload: axios.patch(`/api/person/${userId}/changePassword`, null, {
            params: {
                currentPassword: currentPassword,
                newPassword: newPassword
            }
        })
    }
}

export function setInitialPassword(userMail, currentPassword, newPassword) {
    return {
        type: 'SET_INITIAL_PASSWORD',
        payload: axios.post(`/api/person/initialPassword`, null, {
            params: {
                userMail: userMail,
                currentPassword: currentPassword,
                newPassword: newPassword
            }
        }).then(() => {
            window.location.href = '/'
        })
    }
}

export function forgotPassword(userName) {
    return {
        type: 'FORGOT_PASSWORD',
        payload: axios.post(`/api/forgot_password`, null, {
            params: {
                username: userName,
            }
        })
    }
}

export function resetPasswordRequest(userName, resetCode, newPassword) {
    return {
        type: 'RESET_PASSWORD',
        payload: axios.post(`/api/reset_password`, null, {
            params: {
                username: userName,
                resetCode: resetCode,
                newPassword: newPassword
            }
        })
    }
}

export function resetPasswordIncorrect() {
    return {
        type: 'RESET_PASSWORD_INCORRECT',
    }
}

export function adminSetPassword(userName, password) {
    return {
        type: 'ADMIN_SET_PASSWORD',
        payload: axios.post(`/api/admin_set_password`, null, {
            params: {
                username: userName,
                password: password
            }
        })
    }
}


export function deleteUser(username, personId) {
    return (dispatch) => {
        return dispatch(deleteUserApiCall(username, personId))
            .then(() => {
                dispatch(getPeopleCount())
            })
    }
}

export function deleteUserApiCall(username, personId) {
    return {
        type: 'DELETE_USER',
        payload: axios.delete(`/api/admin_delete/${personId}`, {
            params: {
                username: username
            }
        })
    }
}

export function getUserRoles() {
    return {
        type: 'GET_USER_ROLES',
        payload: axios.get('/api/user/roles')
    }
}

export function getMeetingAsICal(personId) {
    return {
        type: 'GET_MEETING_AS_ICAL',
        payload: axios.get(`/api/person/${personId}/meetingExport`)
    }
}

export function getPersonWithCredential(credential) {
    return {
        type: 'GET_PERSON',
        payload: axios.get('/api/person/credential', {
            params: {
                credential: credential,
            }
        }).catch(err => console.log(err))
    }
}

export function uploadProfilePicture(personId, image, credentials) {
    let formData = new FormData();
    formData.append("multipartFile", image)

    return (dispatch) => {
        return dispatch(uploadImageUser(personId, formData))
            .then((res) => {
                dispatch(getPersonWithCredential(credentials))
            })
    }
}

export function setUploadProfilePicturePending() {
    return {
        type: 'SET_UPLOAD_PROFILE_PICTURE_PENDING',
    }
}

export function uploadImageUser(personId, formData) {
    return {
        type: 'UPLOAD_PROFILE_PICTURE',
        payload: axios.post(`/api/person/${personId}/image`, formData),
        meta: {
            userId: personId
        }

    }
}

export function getProfilePictureOfUser(personId) {
    return {
        type: 'GET_PROFILE_PICTURE_OF_USER',
        payload: axios.get(`/api/person/${personId}/image`)
    }
}

export function deleteProfilePicture(personId) {
    return {
        type: 'DELETE_PROFILE_PICTURE',
        payload: axios.delete(`/api/person/${personId}/image`),
        meta: {
            userId: personId
        }
    }
}

export function getUserGameStatus(personId) {
    return {
        type: 'GET_GAME_STATUS',
        payload: axios.get(`/api/person/${personId}/gameStatus`),
    }
}

export function setUserWorkingHours(personId, workingHours) {
    return {
        type: 'SET_WORKING_HOURS',
        payload: axios.post(`/api/person/${personId}/workingHours`, Object.fromEntries(workingHours))
    }
}

export function setHomezone(personId, floorDto) {
    return {
        type: 'SET_HOMEZONE',
        payload: axios.put(`/api/person/${personId}/homeZone`, floorDto)
    }
}

export function getHomezone(personId) {
    return {
        type: 'GET_HOMEZONE',
        payload: axios.get(`/api/person/${personId}/homeZone`)
    }
}

export function fetchShareStatus(personId) {
    return {
        type: 'FETCH_SHARE_STATUS',
        payload: axios.get(`/api/person/${personId}/shareStatus`)
    }
}

export function setSubscription(personId, data) {
    return {
        type: 'SET_NOTIFICATION_SUBSCRIPTION',
        payload: axios.put(`/api/person/${personId}/subscribeNotifications`, data)
    }
}

export function deleteSubscription(personId) {
    return {
        type: 'DELETE_NOTIFICATION_SUBSCRIPTION',
        payload: axios.delete(`/api/person/${personId}/subscribeNotifications`)
    }
}


export function setNotificationGroups(personId, data) {
    return {
        type: 'SET_NOTIFICATION_GROUPS',
        payload: axios.patch(`/api/person/${personId}/notificationGroups`, data)
    }
}

export function getNotificationGroups(personId) {
    return {
        type: 'GET_NOTIFICATION_GROUPS',
        payload: axios.get(`/api/person/${personId}/notificationGroups`)
    }
}

export function sendNotification(personId) {
    return {
        type: 'SEND_NOTIFICATION',
        payload: axios.post(`/api/notification/sendNotification`, null, {
            params: {
                personId: personId
            }
        })
    }
}

export function postShareStatus(personId, shareStatus, workingGroups) {
    let statusObj = {
        status: shareStatus,
    }

    if (shareStatus === SHARE_STATUS_WORKINGGROUPS) {
        let groups = []
        if (workingGroups && workingGroups.length)
            workingGroups.forEach(group => groups.push({id: group.id}))
        statusObj.workingGroups = groups
    }

    return {
        type: 'POST_SHARE_STATUS',
        payload: axios.post(`/api/person/${personId}/shareStatus`, statusObj)
    }
}

export function getAssignmentsOfUser(userId, showPastAssignments, showAllAssignmentsOfToday) {
    return {
        type: 'GET_ASSIGNMENTS_OF_USER',
        payload: axios.get(`/api/person/${userId}/workplaceAssignment`, {
            params: {
                showPastAssignments: showPastAssignments,
                showAllAssignmentsOfToday: showAllAssignmentsOfToday
            }
        })
    }
}

export function assignMeetingToPerson(personId, assignment, meetingAttendees, externalAttendees) {
    let personsObjectArray = []

    for (let person of meetingAttendees) {
        personsObjectArray.push(
            {
                person: {id: person.id},
                meetingAttendanceStatus: null
            }
        )
    }
    assignment.attendances = personsObjectArray
    assignment.externalAttendances = externalAttendees
    if (assignment.space)
        assignment.space = {id: assignment.space.id}

    return {
        type: 'ASSIGN_MEETING_TO_PERSON',
        payload: axios.post(`/api/person/${personId}/meetingRoomAssignment`, assignment)
    }
}

export function joinMeeting(personId, meetingId) {
    return {
        type: 'JOIN_MEETING',
        payload: axios.post(`/api/personSpaceAssignment/${personId}/joinMeeting/${meetingId}`)
    }
}

export function deleteMeetingRoomAssignment(personId, assignmentId) {
    return {
        type: 'DELETE_MEETING_ASSIGNMENT',
        payload: axios.delete(`/api/person/${personId}/meetingRoomAssignment`, {
            params: {
                assignmentId: assignmentId
            }
        })
    }
}

export function deleteWorkplaceAssignment(personId, assignmentId) {
    return {
        type: 'DELETE_WORKPLACE_ASSIGNMENT',
        payload: axios.delete(`/api/person/${personId}/workplaceAssignment/${assignmentId}`)
    }
}

export function deleteUsersWorkplaceAssignmentsAtDate(personId, date) {
    return {
        type: 'DELETE_WORKPLACE_ASSIGNMENTS_AT_DATE',
        payload: axios.delete(`/api/person/${personId}/deleteWorkplaceAssignmentAtDate`, {
            params: {
                date: date.toISOString()
            }
        })
    }
}

export function setAttendanceStatus(meetingAttendanceStatus, personId, assignmentId) {
    return {
        type: 'SET_ATTENDANCE_STATUS',
        payload: axios.patch(`/api/person/${personId}/attendanceStatus`, {},
            {
                params: {
                    assignmentId: assignmentId,
                    attendanceStatus: meetingAttendanceStatus
                }
            })
    }
}

export function getCoworkersWithAssignmentAtDateFiltered(personId, startDate, endDate, searchString, paginationToken,
                                                         resetPaginationToken, loadOffice = true, loadHomeoffice = true, loadOutOfOffice = true, loadVacation = true) {
    return {
        type: 'GET_COWORKERS_WITH_ASSIGNMENTS_AT_DATE_FILTERED',
        payload: axios.get(`/api/person/${personId}/coworkersWithAssignmentsAtDateFiltered`, {
            params: {
                startDate: new Date(startDate),
                endDate: new Date(endDate),
                searchString: searchString,
                pageToken: resetPaginationToken ? 0 : paginationToken,
                loadOffice: loadOffice,
                loadHomeoffice: loadHomeoffice,
                loadOutOfOffice: loadOutOfOffice,
                loadVacation: loadVacation,
            }
        }),
        meta: {
            resetPaginationToken: resetPaginationToken
        }
    }
}

export function selectCoworker(coworker) {
    return {
        type: 'SELECT_COWORKER',
        payload: coworker,
    }
}

export function fetchAvailabilityStatus(personId) {
    return {
        type: 'FETCH_AVAILABILITY_STATUS',
        payload: axios.get(`/api/person/${personId}/availabilityStatus`)
    }
}

export function setAvailabilityStatus(personId, typeOfAvailabilityStatus, statusText, statusExpiration, isSetAutomatically) {
    let availabilityStatusObj = {
        typeOfAvailabilityStatus: typeOfAvailabilityStatus,
        customText: statusText,
        statusExpiration: statusExpiration,
        isSetAutomatically: isSetAutomatically
    }
    return {
        type: 'SET_AVAILABILITY_STATUS',
        payload: axios.post(`/api/person/${personId}/availabilityStatus`, availabilityStatusObj)
    }
}

export function importUsers(file) {
    let formData = new FormData();
    formData.append("file", file)

    return {
        type: 'IMPORT_USERS',
        payload: axios.post(`/api/person/importUsers/xlsx`, formData),
    }
}
