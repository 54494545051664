import React, {useEffect, useState} from 'react';

import {isEmpty} from "lodash";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory, withRouter} from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import {Hidden, makeStyles, Typography} from "@material-ui/core";

import Bookings from "./Bookings";
import RatingsButton from "../ratings/RatingsButton";
import InstallationDialog from "./InstallationDialog";
import useTheme from "@material-ui/core/styles/useTheme";
import CardWrapper from "../../common/elements/CardWrapper.js";
import CardComponent from "../../common/elements/CardComponent";

import RemoteSvgIcon from "../../common/icons/RemoteSvgIcon.js";
import ResourceSvgIcon from "../../common/icons/ResourceSvgIcon.js";
import ChooseSeatSvgIcon from "../../common/icons/ChooseSeatSvgIcon";
import MeetingRoomSvgIcon from "../../common/icons/MeetingRoomSvgIcon";

import {clearMeetingDetails} from "../../actions/meeting-actions";
import {getUnratedAssignmentsFromPerson} from "../../actions/workplaceAssignment-action";
import {fetchAvailabilityStatus, getAssignmentsOfUser,} from "../../actions/user-action";
import TimeTrackingInfoAndActions from "../../common/elements/TimeTrackingInfoAndActions.js";
import {getCurrentClockState, getTodaysTimeTrackings} from "../../actions/timetracking-actions.js";

import {
    navigate,
    PATH_BOOK_RESOURCE,
    PATH_CHOOSE_SEAT,
    PATH_MEETING,
    PATH_RATINGS,
    PATH_REMOTE,
    PATH_SCAN_QR
} from "../../common/utils/NavigationUtils";
import QrCodeScanSvgIcon from "../../common/icons/QrCodeScanSvgIcon.js";


const useStyle = makeStyles(theme => ({
    root: {
        minHeight: '100%',
        marginTop: '-265px',
        overflow: 'hidden',
        '& .ptr--pull-down-treshold-breached .ptr__pull-down': {
            paddingTop: theme.header.big.height,
        },
        '& .ptr, .ptr__children': {
            overflow: 'visible !important'
        },
    },
    content: {
        padding: theme.paddingContentContainer.padding,
        paddingTop: theme.header.big.height,
        width: '100%',
        boxSizing: 'border-box',
        maxWidth: theme.gridSizes.maxWidth,
        marginTop: '3.6rem',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap
    },
    header: {
        width: '100%',
        display: 'block',
        justifyContent: 'start',
    },
    subHeading: {
        textAlign: 'start',
        marginBottom: '1.2rem'
    },
    icon: {
        width: '30px !important',
        height: '30px !important',
        display: 'flex',
    },
    cardsContainer: {
        justifyContent: 'center',
        gap: theme.innerGap,
        display: 'flex',
    },
    pullable: {
        alignSelf: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        maxWidth: theme.gridSizes.maxWidth,
    },
    dots: {
        '& .lds-ellipsis div:nth-child(1)': {
            backgroundColor: theme.colors.palette.corporate.skinMain
        },
        '& .lds-ellipsis div:nth-child(2)': {
            backgroundColor: theme.colors.palette.corporate.greenLightest
        },
    },
    bookingOptions: {
        textAlign: 'start',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.innerGap
    },
    paddingWrapper: {
        padding: theme.innerGap,
    },
    qrCode: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        '& .MuiSvgIcon-root': {
            width: '4rem',
            height: '4rem'
        },
    }
}));

function HomeScreen({
                        person, assignments, unratedAssignments, getUnratedAssignmentsFromPerson,
                        userClockState,
                        fetchAvailabilityStatus,
                        getCurrentClockState,
                        getTodaysTimeTrackings,
                        todaysTimeTrackings,
                        getAssignmentsOfUser,
                        clearMeetingDetails, location, showTimeTracking
                    }) {

    const theme = useTheme()
    const {t} = useTranslation()
    const classes = useStyle(theme)

    const bookingRef = React.createRef()
    const history = useHistory()
    const [deferredPrompt, setDeferredPrompt] = React.useState(null)
    const localStorageSelection = localStorage.getItem('installationSelection')
    const [installationDialog, setInstallationDialog] = useState(false);

    useEffect(() => {
        if (bookingRef.current && location.hash === ('#bookings')) {
            bookingRef.current.scrollIntoView({
                behavior: "smooth",
                block: 'start'
            });
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!isEmpty(person)) {
            if (isEmpty(unratedAssignments)) {
                loadUnratedAssignments()
            }
            if (showTimeTracking) {
                getCurrentClockState(person.id)
                getTodaysTimeTrackings(person.id)
            }
        }
        // eslint-disable-next-line
    }, [person])

    useEffect(() => {
        if (!isEmpty(person) && !isEmpty(todaysTimeTrackings)) {
            fetchAvailabilityStatus(person.id)
        }
        // eslint-disable-next-line
    }, [todaysTimeTrackings])

    const loadUnratedAssignments = () => {
        getUnratedAssignmentsFromPerson(person.id)
    }

    const handleOnChooseSeatCardClick = () => {
        navigate(history, PATH_CHOOSE_SEAT)
    }

    const handleOnResourceCardClick = () => {
        navigate(history, PATH_BOOK_RESOURCE);
    }

    const handleOnMeetingCardClick = () => {
        clearMeetingDetails()
        navigate(history, PATH_MEETING);
    }

    const handleOnHomeofficeClick = () => {
        navigate(history, PATH_REMOTE);
    }

    const handleOnRatingsClick = () => {
        navigate(history, PATH_RATINGS);
    }

    const refresh = () => {
        loadUnratedAssignments()
        fetchAvailabilityStatus(person.id)

        if (showTimeTracking) {
            getCurrentClockState(person.id)
            getTodaysTimeTrackings(person.id)
        }
        return new Promise((resolve, reject) => {
            getAssignmentsOfUser(person.id, false, true)
                .then(resolve)
                .catch(error => {
                    console.log(error)
                    reject()
                })
        });
    }

    function onDialogClose() {
        setInstallationDialog(false)
    }

    useEffect(() => {
        window.addEventListener('beforeinstallprompt', e => {
            e.preventDefault();
            setDeferredPrompt(e);
            isInstalled() ? setInstallationDialog(false) : setInstallationDialog(true)
        })
    }, [setInstallationDialog]);

    const isInstalled = () => { //get display mode of PWA
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        if (document.referrer.startsWith('android-app://')) {
            return true; // twa (trusted web activity)
        } else if (navigator.standalone || isStandalone) {
            return true; //standalone
        }
        return false; //browser
    }

    return (
        <div className={classes.root}>
            {(localStorageSelection !== false.toString()) && installationDialog ?
                <InstallationDialog open={installationDialog} closeDialog={onDialogClose}
                                    deferredPrompt={deferredPrompt}/>
                : null
            }
            <PullToRefresh onRefresh={refresh} isPullable={!isEmpty(person)}
                           className={classes.dots}>
                <div className={classes.content}>
                    {showTimeTracking
                        ? <div className={classes.cardsContainer}>
                            <Hidden mdUp>
                                <CardWrapper isFlex className={classes.paddingWrapper}>
                                    <div className={classes.qrCode} onClick={() => navigate(history, PATH_SCAN_QR)}>
                                        <QrCodeScanSvgIcon width={40} height={40}/>
                                    </div>
                                </CardWrapper>
                            </Hidden>
                            <CardWrapper fullWidth className={classes.paddingWrapper}>
                                <TimeTrackingInfoAndActions/>
                            </CardWrapper>
                        </div>
                        : null}

                    <div className={classes.bookingOptions}>
                        <div style={{display: 'flex', gap: theme.innerGap, alignItems: 'center'}}>
                            <Typography variant={'h5'}>
                                {t('choose_workstyle')}
                            </Typography>
                        </div>

                        <div className={classes.cardsContainer}>
                            <CardComponent
                                halfWidth
                                icon={<ChooseSeatSvgIcon stroke="#333" className={classes.icon}/>}
                                boldText={t("homeScreen_button_choose")}
                                text={t("homeScreen_button_workplace")}
                                onClickCallback={handleOnChooseSeatCardClick}
                            />
                            <CardComponent
                                halfWidth
                                icon={<MeetingRoomSvgIcon stroke={'#333'} className={classes.icon}/>}
                                boldText={t("homeScreen_button_create")}
                                text={t("homeScreen_button_meeting")}
                                onClickCallback={handleOnMeetingCardClick}
                            />
                        </div>
                        <div className={classes.cardsContainer}>
                            <CardComponent
                                halfWidth
                                icon={<ResourceSvgIcon className={classes.icon}/>}
                                boldText={t("homeScreen_button_resources_main")}
                                text={t("homeScreen_button_resources_sub")}
                                onClickCallback={handleOnResourceCardClick}
                            />
                            <CardComponent
                                halfWidth
                                icon={<RemoteSvgIcon className={classes.icon}/>}
                                boldText={t("homeScreen_button_work_remote")}
                                text={t("homeScreen_button_work_remote_small")}
                                onClickCallback={handleOnHomeofficeClick}
                            />
                        </div>
                    </div>
                    <div>
                        {unratedAssignments && Array.isArray(unratedAssignments) && unratedAssignments.length >= 1 ?
                            <RatingsButton value={unratedAssignments.length}
                                           handleClick={handleOnRatingsClick}
                            /> : null
                        }
                    </div>
                    <div ref={bookingRef} style={{display: 'none'}}/>
                    <Bookings/>
                </div>
            </PullToRefresh>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        person: state.user.person,
        user: state.user.user,
        unratedAssignments: state.workplace.unratedAssignments,
        assignments: state.user.userAssignments,
        userClockState: state.user.clockState,
        todaysTimeTrackings: state.user.todaysTimeTrackings,
        showTimeTracking: state.userConfig.showTimeTracking,
    }
};

const mapDispatchToProps = {
    getUnratedAssignmentsFromPerson: getUnratedAssignmentsFromPerson,
    clearMeetingDetails: clearMeetingDetails,
    getAssignmentsOfUser: getAssignmentsOfUser,
    fetchAvailabilityStatus: fetchAvailabilityStatus,
    getCurrentClockState: getCurrentClockState,
    getTodaysTimeTrackings: getTodaysTimeTrackings,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeScreen));
