import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import * as moment from "moment";
import clsx from "clsx";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";

import {MAX_DAYS_OF_BOOKING} from "../common/utils/AssignmentUtils.js";
import {isSameDay, isWeekend} from "../common/utils/TimeUtils.js";


const useStyles = props => makeStyles(theme => ({
    root: {
        width: '100%',
        ...props.innerClasses?.root,
    },
    header: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        ...props.innerClasses?.header,
    },
    body: {
        display: 'flex',
        height: '57px',
        ...props.innerClasses?.body,
    },
    innerBody: {
        boxSizing: 'border-box',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        overflowY: 'hidden',
        [theme.breakpoints.up('xxxl')]: {
            padding: '0 3px',
        },
        ...props.innerClasses?.innerBody,
    },
    buttonRoot: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        ...props.innerClasses?.buttonRoot,
    },
    buttonButton: {
        borderRadius: '50%',
        width: '36px',
        height: '36px',
        maxWidth: '36px',
        minWidth: '36px',
        marginBottom: '3px',
        ...props.innerClasses.buttonButton,
    },
    selected: {
        background: theme.colors.palette.corporate.skinMain + ' !important',
        color: theme.colors.palette.neutral.white,
        ...props.innerClasses?.selected,
    },
    buttonCaption: {
        ...props.innerClasses.buttonCaption
    },
    buttonContent: {
        ...props.innerClasses?.buttonContent,
    },
    buttonContentSelected: {
        ...props.innerClasses?.buttonContentSelected,
    },
    buttonContentDisabled: {
        ...props.innerClasses?.buttonContentSelected,
    }
}));

const findSelectedIndex = (dateArray, selectedDate, validation, setDate, preventInitialCallback = false, showIndicator, skipDate) => {
    let index = selectedDate ?
        dateArray.findIndex(date =>
            moment(date).dayOfYear() === moment(selectedDate).dayOfYear())
        : 0

    if (index === -1)
        index = 0

    if (index === 0 && !selectedDate) {
        if (!preventInitialCallback)
            setDate(dateArray[index])

        return index
    }

    for (let i = index; i < dateArray.length; i++) {
        if (!validation(dateArray[i]) && (!skipDate || !skipDate(dateArray[i]))) {
            // if (!validation(dateArray[i]) && (!skipDate || !showIndicator(dateArray[i]))) {
            if (i !== index && !preventInitialCallback)
                setDate(dateArray[i])

            return i
        }
    }
    return 0
}

const getDateArray = (startDate, endDate) => {
    const dates = []
    let current = moment(startDate)
    let end = moment(endDate)

    if (end.diff(current) < 0) {
        console.warn('enddate before startdate! Datepicker swaps dates.')
        let tmp = current
        current = end
        end = tmp
    }

    while (current <= end) {
        dates.push(moment(current).toDate())
        current = moment(current).add(1, 'days')
    }

    return dates
};

function isDisabled(date, disabledCallback, disableWeekends) {
    return (disabledCallback && disabledCallback(date)) || (disableWeekends && isWeekend(date))
}

function DateButton({date, selectedDate, showIndicator, onButtonClick, ...props}) {
    const theme = useTheme()
    const classes = useStyles(props)(theme);

    return (
        <div className={classes.buttonRoot}>
            <Typography className={classes.buttonCaption} style={{userSelect: 'none'}}
                        variant={props.greyOutDate && props.greyOutDate(date) ? 'caption' : 'overline'}>
                {moment(date).format('dd')}
            </Typography>
            <Button className={clsx({
                [classes.buttonButton]: true,
                [classes.selected]: selectedDate
            })}
                    style={selectedDate ? {...props.innerClasses.selected} : null}
                    onClick={onButtonClick} disabled={isDisabled(date, props.disableDate, props.disableWeekend)}>
                <div style={{flexDirection: 'column', display: 'flex', alignItems: 'center'}}>
                    <Typography className={clsx({
                        [classes.buttonContent]: true,
                        [classes.buttonContentSelected]: selectedDate,
                        [classes.buttonContentDisabled]: props.disableDate(date),
                    })}
                                variant={isDisabled(date, props.disableDate, props.disableWeekend) ? 'h6' : selectedDate ? 'body2' : 'body1'}>
                        {moment(date).format('D')}
                    </Typography>
                    {!selectedDate && !isDisabled(date, props.disableDate, props.disableWeekend) && showIndicator(date) &&
                        <IndicatorDot/>}
                </div>
            </Button>
        </div>
    );
}

function IndicatorDot({height, width, color, ...props}) {
    const theme = useTheme()

    return (
        <div style={{
            height: height ?? '2px',
            width: width ?? '6px',
            borderRadius: '20%',
            background: color ?? theme.colors.palette.neutral.greyMain
        }}/>
    );
}

function SingleLineDatePicker({
                                  startDate,
                                  numberOfDays,
                                  selected,
                                  caption,
                                  local,
                                  onChange,
                                  preventInitialCallback,
                                  className,
                                  showDate,
                                  skipDate,
                                  showIndicator,
                                  ...props
                              }) {
    const theme = useTheme()
    const classes = useStyles(props)(theme);
    const endDate = new Date()
    endDate.setDate(endDate.getDate() + (numberOfDays > 1 ? numberOfDays - 1 : 1))
    //keep order of these variables
    const [dateArray,] = useState(getDateArray(startDate, endDate));
    const [index, setIndex] = useState(findSelectedIndex(dateArray, selected, (date) => isDisabled(date, props.disableDate, props.disableWeekend), onChange, preventInitialCallback))
    const [selectedDate, setSelectedDate] = useState(index);

    useEffect(() => {
        if (!isSameDay(selected, selectedDate) || selected === null) {
            setIndex(findSelectedIndex(dateArray, selected, (date) => isDisabled(date, props.disableDate, props.disableWeekend), onChange, preventInitialCallback, showIndicator, skipDate))
        }
        // eslint-disable-next-line
    }, [showIndicator])

    useEffect(() => {
        if (index !== selectedDate)
            setSelectedDate(index)
        // eslint-disable-next-line
    }, [index])

    const onButtonClicked = (_index, date) => {
        setSelectedDate(_index)
        onChange(date)
    }

    return (
        <div className={clsx(classes.root, className)}>
            {showDate && <div className={classes.header}>
                <Typography>
                    {moment(dateArray[selectedDate]).locale(local).format(props.dateFormat)}
                </Typography>
                {caption ? <Typography variant={'h6'}>
                    {caption}
                </Typography> : null}
            </div>}

            <div className={classes.body}>
                <div className={classes.innerBody}
                     data-body-scroll-lock-ignore="true">
                    {
                        dateArray.map((date, _index) =>
                            <DateButton key={_index} onButtonClick={() => onButtonClicked(_index, date)} date={date}
                                        showIndicator={showIndicator}
                                        innerClasses={props.innerClasses}
                                        selectedDate={_index === selectedDate} {...props}>
                                {moment(date).locale(local).format(props.dateFormat)}
                            </DateButton>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

SingleLineDatePicker.propTypes = {
    startDate: PropTypes.instanceOf(Date).isRequired,
    numberOfDays: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    dateFormat: PropTypes.string,
    local: PropTypes.string,
    selected: PropTypes.any,
    caption: PropTypes.string,
    color: PropTypes.string,
    disableDate: PropTypes.func,
    disableWeekend: PropTypes.bool,
    showIndicator: PropTypes.func,
    skipDate: PropTypes.func,
    greyOutDate: PropTypes.func,
    preventInitialCallback: PropTypes.bool,
    showDate: PropTypes.bool,
    innerClasses: PropTypes.object,
};

SingleLineDatePicker.defaultProps = {
    numberOfDays: MAX_DAYS_OF_BOOKING,
    dateFormat: 'dddd, MMM D',
    caption: 'Date',
    local: 'en',
    preventInitialCallback: false,
    disableDate: (date) => {
        return false
    },
    showIndicator: () => {
        return false
    },
    skipDate: () => {
        return false
    },
    innerClasses: {},
};

export default SingleLineDatePicker;