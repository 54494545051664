import React, {useEffect, useState} from 'react';

import {isEmpty} from "lodash";
import {connect} from "react-redux";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import {Form, withFormik} from 'formik';
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper/Paper";
import useTheme from "@material-ui/core/styles/useTheme";
import {makeStyles, Typography} from "@material-ui/core";

import DurationSlider from "./DurationSlider";
import CustomTextField from "../CustomTextField";
import CustomButton from "../CustomButton.js";
import StaticBottomSheet from "../../common/elements/StaticBottomSheet";
import HeaderWithTitleAndBackButton from "../../common/elements/HeaderWithTitleAndBackButton";
import {navigate, PATH_HOME, PATH_MEETING_DETAILS, PATH_MEETING_SUGGESTIONS} from "../../common/utils/NavigationUtils";
import {
    getSuggestedMeetingSlots,
    setMeetingInformation,
    updateExternalMeetingAttendees,
    updateMeetingAttendees,
    updateMeetingInvitedGroups
} from "../../actions/meeting-actions";

import CustomizedSwitch from "../../common/elements/CustomizedSwitch.js";
import SelectPersonsWithModal from "../people/SelectPersonsWithModal.js";

const useStyle = makeStyles(theme => ({
    root: {
        width: '100%',
        minHeight: '100%',
        flex: 1,
    },
    container: {
        minHeight: `calc(100% - ${theme.staticBottomActionSheet.height})`,
        maxWidth: '100%',
        padding: theme.paddingContentContainer.padding,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap,
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.outerGap
    },
    caption: {
        textAlign: 'left',
    },
    buttonContainer: {
        gap: '1.2rem',
    },
    switchContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    selectedPersons: {
        marginTop: '1.2rem'
    }
}));

function MeetingPageForm({
                             userId,
                             updateMeetingAttendees,
                             updateExternalMeetingAttendees,
                             values,
                             setFieldValue,
                             meetingAttendees,
                             externalAttendees,
                             meetingGroups,
                             handleSubmit,
                             errors,
                             pending,
                             updateMeetingInvitedGroups,
                             ...props
                         }) {

    const theme = useTheme()
    const {t} = useTranslation()
    const history = useHistory()
    const classes = useStyle(theme);

    const [meetingAttendeesState, setMeetingAttendeesState] = useState(meetingAttendees && meetingAttendees.length ? meetingAttendees : []);
    const [externalAttendeesState, setExternalAttendeesState] = useState(externalAttendees && externalAttendees.length ? externalAttendees : []);
    const [selectedGroupsState, setSelectedGroupsState] = useState(meetingGroups && meetingGroups.length ? meetingGroups : []);

    useEffect(() => {
        updateMeetingAttendees([...meetingAttendeesState])
        // eslint-disable-next-line
    }, [meetingAttendeesState]);

    useEffect(() => {
        updateExternalMeetingAttendees([...externalAttendeesState])
        // eslint-disable-next-line
    }, [externalAttendeesState]);

    useEffect(() => {
        updateMeetingInvitedGroups([...selectedGroupsState])
        // eslint-disable-next-line
    }, [selectedGroupsState]);

    return (
        <Grid container component={Paper} className={classes.root}>
            <Form onSubmit={handleSubmit} style={{width: '100%'}}>
                <Grid item container direction={'column'} wrap={'nowrap'} justifyContent={'space-between'}
                      className={classes.container}>
                    <HeaderWithTitleAndBackButton title={t('meeting')} backLabel={t("home")}
                                                  onNavigateBack={() => navigate(history, PATH_HOME)}/>


                    <Grid item container direction={'column'} className={classes.content}>
                        <CustomTextField title={t('title') + '*'} placeholder={t('add_title')} autoFocus
                                         value={values.meetingTitle}
                                         onChange={e => {
                                             e.preventDefault();
                                             const {value} = e.target;
                                             setFieldValue("meetingTitle", value);
                                         }}/>
                        <CustomTextField title={t('description')} placeholder={t('description_details')} rows={4}
                                         max={2048} value={values.meetingDescription}
                                         onChange={e => {
                                             e.preventDefault();
                                             const {value} = e.target;
                                             setFieldValue("meetingDescription", value);
                                         }}/>
                        <CustomTextField title={t('meeting_link')} placeholder={t('meeting_link_details')}
                                         value={values.onlineLink}
                                         onChange={e => {
                                             e.preventDefault();
                                             const {value} = e.target;
                                             setFieldValue("onlineLink", value);
                                         }}/>

                        <div className={classes.switchContainer}>
                            <Typography variant={'h6'} className={classes.caption}>
                                {t('exclusively_online')}
                            </Typography>
                            <CustomizedSwitch checked={values.onlineOnly}
                                              onChange={(e, value) => {
                                                  setFieldValue("onlineOnly", value);
                                              }}/>
                        </div>

                        <Divider style={{width: '100%'}}/>

                        <DurationSlider color={theme.colors.palette.neutral.white} value={values.duration}
                                        onChange={value => {
                                            setFieldValue("duration", value);
                                        }}/>


                        <SelectPersonsWithModal initialSelectedPersons={meetingAttendeesState}
                                                initialSelectedExternalPersons={externalAttendeesState}
                                                initialSelectedWorkingGroups={selectedGroupsState}
                                                withWorkingGroups
                                                allowExternalPersons
                                                onConfirmCallback={(persons, groups, externals) => {
                                                    if (persons)
                                                        setMeetingAttendeesState(persons)
                                                    if (groups)
                                                        setSelectedGroupsState(groups)
                                                    if (externals)
                                                        setExternalAttendeesState(externals)
                                                }}/>
                    </Grid>

                </Grid>

                <StaticBottomSheet>
                    <Grid item container wrap={'nowrap'} className={classes.buttonContainer}>
                        <CustomButton text={t('find_meeting')}
                                      primary
                                      isLoading={pending}
                                      disabled={!!errors.title}
                                      onClick={() => {
                                          setFieldValue("moreDetails", false)
                                          handleSubmit()
                                      }}
                        />
                        <CustomButton text={t('more_details')} textColor={theme.colors.palette.neutral.darkMain}
                                      disabled={!!errors.title || pending}
                                      onClick={() => {
                                          setFieldValue("moreDetails", true)
                                          handleSubmit()
                                      }}
                        />
                    </Grid>
                </StaticBottomSheet>
            </Form>
        </Grid>
    );
}

const MeetingPage = withFormik({
    mapPropsToValues: props => {
        let title = props.meetingTitle ? props.meetingTitle : ''
        let description = props.meetingDescription ? props.meetingDescription : ''
        let link = props.onlineLink ? props.onlineLink : ''
        let duration = props.duration ? props.duration : 1
        let onlineOnly = props.onlineOnly ? props.onlineOnly : false
        return ({
            meetingTitle: title,
            meetingDescription: description,
            onlineLink: link,
            duration: duration,
            onlineOnly: onlineOnly
        })
    },
    enableReinitialize: true,
    validateOnMount: true,
    handleSubmit: (values, {props}) => {
        props.setMeetingInfos(values.meetingTitle, values.meetingDescription, values.onlineLink, values.duration, values.onlineOnly)

        let invitedPersons = [...props.meetingAttendees]
        props.meetingGroups.forEach(group => {
            group.members.forEach(person => {
                if (!invitedPersons.some(p => p.id === person.id)) {
                    invitedPersons.push(person)
                }
            })
        })

        if (!invitedPersons.some(p => p.id === props.userId)) {
            invitedPersons.push({id: props.userId})
        }
        if (values.moreDetails) {
            navigate(props.history, PATH_MEETING_DETAILS)
        } else {
            const meetingInfo = {
                title: values.meetingTitle,
                description: values.meetingDescription,
                onlineLink: values.onlineLink,
                onlineOnly: values.onlineOnly,
                duration: values.duration * 3600,
                requiredParticipants: invitedPersons,
                externParticipantsEmails: [...props.externalAttendees].map(e => e.email),
                optionalParticipants: [],
            }

            props.getSuggestedMeetingSlots(meetingInfo, props.userId)
                .then(() => navigate(props.history, PATH_MEETING_SUGGESTIONS))
        }
    },
    validate: (values) => {
        const errors = {};

        if (isEmpty(values.meetingTitle)) {
            errors.title = 'Required'
        }

        return errors
    }
})(MeetingPageForm)

const mapStateToProps = state => {
    return {
        userId: state.user.person.id,
        meetingTitle: state.meetings.title,
        meetingDescription: state.meetings.description,
        onlineLink: state.meetings.onlineLink,
        onlineOnly: state.meetings.onlineOnly,
        meetingAttendees: state.meetings.meetingAttendees,
        externalAttendees: state.meetings.externalAttendees,
        meetingGroups: state.meetings.meetingGroups,
        duration: state.meetings.duration,
        pending: state.meetings.suggestionsPending,
    }
}

const mapDispatchToProps = {
    setMeetingInfos: setMeetingInformation,
    updateMeetingAttendees: updateMeetingAttendees,
    updateExternalMeetingAttendees: updateExternalMeetingAttendees,
    updateMeetingInvitedGroups: updateMeetingInvitedGroups,
    getSuggestedMeetingSlots: getSuggestedMeetingSlots,
}

export default connect(mapStateToProps, mapDispatchToProps)(MeetingPage);