import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types'
import Divider from "@material-ui/core/Divider";
import {makeStyles, useTheme} from "@material-ui/core";

import SearchBar from "../common/elements/Searchbar";
import BookingsEmptyStateSvg from "../common/icons/BookingsEmptyStateSvg";
import InfiniteLoaderList from "../common/elements/InfiniteLoaderList";
import * as _ from 'lodash'


const useStyle = props => makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        gap: theme.innerGap,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    list: {
        width: '100%',
        overflow: 'hidden',
        overflowX: 'hidden',
        overscrollBehavior: 'contain',
        gap: theme.innerGap,
        height: 'calc(100% - 78px)',
        display: 'flex',
        flexDirection: 'column'
    },
    emptyState: {
        width: '100%',
    },
}));

function InfiniteLoaderListWithSearchbar({
                                             open,
                                             items,
                                             getItem,
                                             hasNextPage,
                                             loadNextPage,
                                             pageLoading,
                                             onClose,
                                             searchPlaceholder,
                                             showSpecialSelectedItems,
                                             labelInfiniteLoaderList,
                                             searchCallback,
                                             specialItemsValidation,
                                             specialItemsCreation,
                                             ...props
                                         }) {

    const theme = useTheme()
    const classes = useStyle(props)(theme);
    const timer = useRef(0)
    const [searchString, setSearchString] = useState('');
    const [listItems, setListItems] = useState(items);

    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current)
        }

        timer.current = setTimeout(() => {
            loadNextPage(searchString, true)
        }, 500)

        // eslint-disable-next-line
    }, [searchString]);

    useEffect(() => {
        const filteredItems = searchCallback(items, searchString)
        if (showSpecialSelectedItems && specialItemsValidation(searchString) && !filteredItems.length) {
            filteredItems.push(specialItemsCreation(searchString))
        }
        setListItems(filteredItems)
        // eslint-disable-next-line
    }, [items]);

    const onPageLoad = () => {
        return new Promise(resolve => {
            loadNextPage(searchString)
            resolve();
        });
    }

    return (
        <div className={classes.root}>
            <SearchBar placeholder={searchPlaceholder}
                       searchString={searchString}
                       setSearchString={setSearchString}/>

            <Divider className={classes.divider}/>

            <div className={classes.list}
                 style={{height: '100%', display: 'flex', flex: 1, flexDirection: 'column'}}>
                <InfiniteLoaderList hasNextPage={hasNextPage}
                                    isNextPageLoading={pageLoading}
                                    items={listItems}
                                    loadNextPage={onPageLoad}
                                    listItem={getItem}
                                    emptyState={<BookingsEmptyStateSvg className={classes.emptyState}/>}/>
            </div>
        </div>
    );
}

InfiniteLoaderListWithSearchbar.propTypes = {
    open: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    getItem: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    hasNextPage: PropTypes.bool.isRequired,
    loadNextPage: PropTypes.func.isRequired,
    pageLoading: PropTypes.bool.isRequired,
    searchPlaceholder: PropTypes.string,
    showSpecialSelectedItems: PropTypes.bool,
    searchCallback: PropTypes.func,
    specialItemsValidation: PropTypes.func,
    specialItemsCreation: PropTypes.func,
};

InfiniteLoaderListWithSearchbar.defaultProps = {
    open: false,
    items: [],
    loadNextPage: () => {
        console.log('[InfiniteLoaderListWithSearchbar] -- Reload items')
    },
    searchCallback: _.noop,
    specialItemsValidation: _.noop,
    specialItemsCreation: _.noop,
    searchPlaceholder: 'Search',
};

export default InfiniteLoaderListWithSearchbar;