const initialState = {
    maxBookings: -1,
    meetingsLimit: -1,
    capacity: 100,
    terminals: [],
    adminResourceCalendars: [],
    userManagement: null,
    resetPending: false,
}

function adminConfigReducer(state = initialState, action) {

    switch (action.type) {

        case 'GET_ADMIN_CONFIG_FULFILLED':
            return {
                ...state,
                meetingsLimit: action.payload.data.maxNumberOfInPersonMeetingAttendances,
                maxBookings: action.payload.data.bookingsPerPersonAndWeek,
                capacity: action.payload.data.capacity,
                terminals: action.payload.data.terminals,
                userManagement: action.payload.data.userManagement,
            }

        case 'SET_LIMIT_MEETING_FULFILLED':
            return {
                ...state,
                meetingsLimit: action.payload.data.maxNumberOfInPersonMeetingAttendances,
            }

        case 'SET_LIMIT_BOOKINGS_FULFILLED':
            return {
                ...state,
                maxBookings: action.payload.data.bookingsPerPersonAndWeek
            }

        case 'SET_CAPACITY_FULFILLED':
            return {
                ...state,
                capacity: action.payload.data.capacity
            }

        case 'GET_ADMIN_CALENDARS_FULFILLED':
            return {
                ...state,
                adminResourceCalendars: action.payload.data.data ?? []
            }

        case 'RESET_EXTERNAL_USER_MANAGEMENT_PENDING': {
            return {
                ...state,
                resetPending: true,
            }
        }

        case 'RESET_EXTERNAL_USER_MANAGEMENT_FULFILLED': {
            return {
                ...state,
                userManagement: null,
                resetPending: false,
            }
        }

        default:
            return state

    }
}

export default adminConfigReducer
