import {isSameDay} from "../common/utils/TimeUtils.js";

const initialState = {
    seatOccupancies: [],
    getSeatOccupanciesPending: false,
    homeOfficeOccupancies: [],
    activityOccupancies: [],
    getActivityOccupanciesPending: false,
    seatOccupanciesGroupedByWorkplace: [],
    ratingDeviationsGroupedByWorkplace: [],
    occupancy: [],
    occupancyPending: false,
    distribution: [],
    getDistributionPending: false,
    sustainability: 0,
    getSustainabilityPending: false,
    nrMeetings: [],
    getNrMeetingsPending: false,
    averageAttendees: [],
    getAverageAttendeesPending: false,
    userDistributionStatisticsAllTime: {},
    userDistributionStatistics: {},
    userDistributionStatisticsPending: false,
    userMeetingStatistics: {},
    userMeetingStatisticsPending: false,
    gamificationPointUtils: null,
    userTimeTrackingStatistics: [],
    userTimeTrackingStatsPending: false
}

function dashboardReducer(state = initialState, action) {

    switch (action.type) {

        case 'GET_STATISTICS_BUILDING_OCCUPANCY_PENDING': {
            return {
                ...state,
                occupancyPending: true,
            }
        }

        case 'GET_STATISTICS_BUILDING_OCCUPANCY_FULFILLED': {
            return {
                ...state,
                occupancyPending: false,
                occupancy: action.payload.data
            }
        }

        case 'GET_NUMBER_OF_OCCUPIED_SEATS_PENDING':
            return {
                ...state,
                getSeatOccupanciesPending: true
            }

        case 'GET_NUMBER_OF_OCCUPIED_SEATS_REJECTED':
            return {
                ...state,
                getSeatOccupanciesPending: false,
            }

        case 'GET_NUMBER_OF_OCCUPIED_SEATS_FULFILLED':
            return {
                ...state,
                getSeatOccupanciesPending: false,
                seatOccupancies: action.payload.data
            }
        case 'GET_NUMBER_OF_OCCUPIED_SEATS_PER_WORKPLACE_FULFILLED':

            return {
                ...state,
                seatOccupanciesGroupedByWorkplace: action.payload.data
            }
        case 'GET_NUMBER_OF_OCCUPIED_SEATS_PER_WORKPLACE_PENDING':

            return {
                ...state,
                seatOccupanciesGroupedByWorkplace: []
            }
        case 'GET_RATING_DEVIATIONS_PER_WORKPLACE_FULFILLED':

            return {
                ...state,
                ratingDeviationsGroupedByWorkplace: action.payload.data
            }
        case 'GET_RATING_DEVIATIONS_PER_WORKPLACE_PENDING':

            return {
                ...state,
                ratingDeviationsGroupedByWorkplace: []
            }
        case 'GET_NUMBER_OF_HOMEOFFICE_SEATS_FULFILLED':
            return {
                ...state,
                homeOfficeOccupancies: action.payload.data
            }

        case 'GET_ACTIVITY_STATISTICS_PENDING':
            return {
                ...state,
                getActivityOccupanciesPending: true
            }

        case 'GET_ACTIVITY_STATISTICS_REJECTED':
            return {
                ...state,
                getActivityOccupanciesPending: false
            }

        case 'GET_ACTIVITY_STATISTICS_FULFILLED':
            return {
                ...state,
                getActivityOccupanciesPending: false,
                activityOccupancies: action.payload.data
            }

        case 'GET_DISTRIBUTION_STATISTICS_PENDING':
            return {
                ...state,
                getDistributionPending: action.meta,
            }

        case 'GET_DISTRIBUTION_STATISTICS_REJECTED':
            return {
                ...state,
                getDistributionPending: false,
            }

        case 'GET_DISTRIBUTION_STATISTICS_FULFILLED':
            return {
                ...state,
                getDistributionPending: false,
                distribution: action.payload.data
            }
        case 'GET_SUSTAINABILITY_STATISTICS_PENDING':
            return {
                ...state,
                getSustainabilityPending: true
            }
        case 'GET_SUSTAINABILITY_STATISTICS_REJECTED':
            return {
                ...state,
                getSustainabilityPending: false
            }
        case 'GET_SUSTAINABILITY_STATISTICS_FULFILLED':
            return {
                ...state,
                getSustainabilityPending: false,
                sustainability: action.payload.data
            }
        case 'GET_NR_MEETINGS_STATISTICS_PENDING':
            return {
                ...state,
                getNrMeetingsPending: true
            }
        case 'GET_NR_MEETINGS_STATISTICS_REJECTED':
            return {
                ...state,
                getNrMeetingsPending: false
            }
        case 'GET_NR_MEETINGS_STATISTICS_FULFILLED':
            return {
                ...state,
                getNrMeetingsPending: false,
                nrMeetings: action.payload.data.data
            }
        case 'GET_AVERAGE_ATTENDEES_STATISTICS_PENDING':
            return {
                ...state,
                getAverageAttendeesPending: true
            }
        case 'GET_AVERAGE_ATTENDEES_STATISTICS_REJECTED':
            return {
                ...state,
                getAverageAttendeesPending: false
            }
        case 'GET_AVERAGE_ATTENDEES_STATISTICS_FULFILLED':
            return {
                ...state,
                getAverageAttendeesPending: false,
                averageAttendees: action.payload.data
            }
        case 'GET_USER_DISTRIBUTION_STATISTICS_ALL_TIME_FULFILLED':
            return {
                ...state,
                userDistributionStatisticsAllTime: action.payload.data
            }
        case 'GET_USER_DISTRIBUTION_STATISTICS_PENDING':
            return {
                ...state,
                userDistributionStatisticsPending: true
            }
        case 'GET_USER_DISTRIBUTION_STATISTICS_FULFILLED':
            return {
                ...state,
                userDistributionStatisticsPending: false,
                userDistributionStatistics: action.payload.data
            }
        case 'GET_USER_DISTRIBUTION_STATISTICS_REJECTED':
            return {
                ...state,
                userDistributionStatisticsPending: false,
            }

        case 'GET_USER_MEETING_STATISTICS_PENDING':
            return {
                ...state,
                userMeetingStatisticsPending: true
            }
        case 'GET_USER_MEETING_STATISTICS_FULFILLED':
            return {
                ...state,
                userMeetingStatisticsPending: false,
                userMeetingStatistics: action.payload.data
            }
        case 'GET_USER_MEETING_STATISTICS_REJECTED':
            return {
                ...state,
                userMeetingStatisticsPending: false,
            }

        case 'GET_GAMIFICATION_POINT_UTILS_FULFILLED':
            return {
                ...state,
                gamificationPointUtils: action.payload.data
            }
        case 'GET_USER_TIME_TRACKING_STATISTICS_PENDING':
            return {
                ...state,
                userTimeTrackingStatsPending: true
            }
        case 'GET_USER_TIME_TRACKING_STATISTICS_FULFILLED':
            return {
                ...state,
                userTimeTrackingStatistics: action.payload.data,
                userTimeTrackingStatsPending: false
            }
        case 'GET_USER_TIME_TRACKING_STATISTICS_REJECTED':
            return {
                ...state,
                userTimeTrackingStatsPending: false
            }

        case 'ADD_TIMETRACKING_FULFILLED':
        case 'UPDATE_TIMETRACKING_FULFILLED':
        case 'ADD_TIMETRACKING_BREAK_FULFILLED':
        case 'DELETE_TIMETRACKING_BREAK_FULFILLED':
        case 'UPDATE_TIMETRACKING_BREAK_FULFILLED': {
            let stats = {...state.userTimeTrackingStatistics}
            Object.entries(stats).forEach(([key, values]) => {
                    if (isSameDay(key, action.payload.data[0].clockInTime)) {
                        stats[key] = action.payload.data
                        return true
                    }
                }
            )
            return {
                ...state,
                userTimeTrackingStatistics: stats
            }
        }
        case 'DELETE_TIMETRACKING_FULFILLED': {
            let stats = {...state.userTimeTrackingStatistics}
            Object.entries(stats).forEach(([key, values]) => {
                    for (let entry of values) {
                        if (entry.id === action.payload.data.id) {
                            stats[key] = [...stats[key].filter(val => val.id !== entry.id)]
                            return true
                        }
                    }
                }
            )
            return {
                ...state,
                userTimeTrackingStatistics: stats
            }
        }

        default:
            return state
    }
}


export default dashboardReducer
