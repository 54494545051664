import React, {useEffect} from 'react';
import {compose} from "redux";
import {withStyles} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import SingleLineDatePicker from "../SingleLineDatePicker";
import CurrentOccupancyListComponent from "./CurrentOccupancyListComponent";
import {setSelectedDate} from "../../actions/workplaceAssignment-action.js";

const styles = theme => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
    },
    datePicker: {
        boxSizing: 'border-box',
        marginBottom: '1.2rem',
    },
})

function CurrentOccupancyList({
                                  classes,
                                  t,
                                  selectedDate,
                                  setSelectedDate,
                                  onSelectAssignment,
                                  theme,
                                  ...props
                              }) {

    useEffect(() => {
        setSelectedDate(new Date())
        // eslint-disable-next-line
    }, [])

    return (
        <div className={classes.root}>

            <SingleLineDatePicker className={classes.datePicker} onChange={date => setSelectedDate(date)}
                                  startDate={new Date()} color={theme.colors.gradients.skinMain}
                                  selected={new Date()}/>
            <CurrentOccupancyListComponent onSelectAssignmentCallback={onSelectAssignment}/>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        selectedDate: state.assignmentBuilder.selectedDate,
    }
}

const mapDispatchToProps = {
    setSelectedDate: setSelectedDate,
}

export default compose(withStyles(styles, {withTheme: true}), withTranslation())(connect(mapStateToProps, mapDispatchToProps)(CurrentOccupancyList))